@import 'susy';

@import 'colors';
@import 'tags';
@import 'mixins';
@import 'carousel';

@import 'base';
@import 'header';

@import 'refactor/button';
@import 'refactor/custom-select';
@import 'refactor/form';

/*home*/
@import 'refactor/home-simulador';
@import 'refactor/modificar-solicitud';

@import 'refactor/form-registro';
@import 'refactor/form-verificar';
@import 'refactor/form-solicitud';
@import 'refactor/form-solicitud2';
@import 'refactor/form-solicitud3';
@import 'refactor/form-solicitud4';

/*Registro*/
@import 'refactor/registro';

/*conoce-y-ahorra*/
@import 'refactor/simu-calcula-tu-credito';
@import 'refactor/simu-tarjeta-de-credito';
@import 'refactor/transfiere-deuda';

/*faqs*/
@import 'refactor/faqs';

/*home*/
@import 'refactor/home';

@import 'seguridad';
@import 'quienes-somos';

@import 'terminos';
@import 'aviso-privacidad';

/*landing transfer*/
@import 'refactor/transfer';

/*login-popup*/
@import 'refactor/login-popup';

/*modals and loading*/
@import 'refactor/modals';

@import 'footer';
