.registro {
   @include span(12);
   .inner {
      @include pad(0.5,0.5);
      @include span(11);
   }
   .absolute {
      @include absolute;
   }
   &__first-block-image {
      @include span(12);
      height: 65%;
      min-height: 560px;
      background-image: url('/images/registro-mobile.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      .text {
         @include span(12);
         position: absolute;
         top: 40%;
         left: 0;
         text-align: center;
         h1 {
            font-size: 2em;
            color: $white;
            .cursive {
               font-size: 1.5em;
               display: block;
            }
         }
      }
   }
   &__first-block-footer {
      display: block;
      @include span(12);
      .inner {
         margin-top: 2em;
      }
      h2 {
         @include fontSize(18px);
         color: $black;
         text-transform: none;
      }
      p {
         color: $black;
         @include fontSize(13px);
         padding: 0.5em 0;
      }
   }
   .second-block {
      @include span(12);
      position: relative;
      &__head {
         @include span(12);
         display: none;
      }
      &__forms {
         @include span(12);
         .modificar-solicitud-second {
            @include span(12);
         }
         .pagination {
            @include span(12);
            margin-bottom: 10%;
            .p-left {
               border: solid 1px $sky-blue;
               border-right: none;
               width: 6px;
               height: 20px;
               display: inline-block;
            }
            .p-center {
               width: 45px;
               height: 22px;
               display: inline-block;
               position: relative;
               p {
                  position: absolute;
                  top: 3.5px;
                  left: 5px;
                  color: $light-gray;
                  font-size: 0.8rem;
               }
            }
            .p-right {
               border: solid 1px $sky-blue;
               border-left: none;
               width: 6px;
               height: 20px;
               display: inline-block;
            }
         }
      }
   }
   @include susy-breakpoint($mobile, $susy){
      .inner {
         @include pad(1,1);
         @include span(10);
      }
      &__first-block-image {
         @include span(12);
         height: 75%;
         min-height: 320px;
         background-image: url('/images/registro_desktop.png');
      }
   }
   @include susy-breakpoint($tablet, $susy){
      &__first-block-image {
         @include span(12);
         height: 75%;
         min-height: 560px;
         background-image: url('/images/registro_desktop.png');
      }
   }
   @include susy-breakpoint($desktop, $susy){
      &__first-block-image {
         @include span(12);
         height: 75%;
         min-height: 560px;
         background-image: url('/images/registro_desktop.png');
      }
   }
}
