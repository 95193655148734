.form-solicitud2{
	@include pad(0.5,0.5);
	@include span(11);
	margin-bottom: 2em;
	&__content{
		@include span(12);
		border-top: solid 1px $light-gray;
		padding: 1em 0;
		h2{
			@include fontSize(18px);
			text-transform: none;
			padding: 0.5em 0;
		}
		p{
			@include fontSize(12px);
		}
	}
	&__row{
		@include span(12);
		margin: 2em 0 0;
	}
	&__item{
		@include span(12);
		margin: 1em 0;
		&__content{
			@include span(12);
			margin: 2em 0;
			.label{
				margin: 1em 0;
			}
			input{
				top: 15px;
			}
			&:nth-child(10n+2){
				margin: 0 0 2em;
				.inputs-nacimiento{
					@include span(12);
					.custom-select{
						&:nth-child(10n+1){
							@include span(4);
						}
						&:nth-child(10n+2){
							@include span(4);
						}
						&:nth-child(10n+3){
							@include span(4);
						}
					}
				}
			}
			&:nth-child(10n+3){
				margin: 0 0 2em;
				.inputs-nacimiento-lugar{
					@include span(12);
					.custom-select{
						&:nth-child(10n+1){
							@include span(6);
						}
						&:nth-child(10n+2){
							@include span(6);
						}
					}
				}
			}
			.trigger-scroll{
				margin-top: 16px;
			}
		}
	}
	&__buttons{
		@include span(12);
		margin-bottom: 2em;
		&__content{
			@include span(12);
			.btn{
	            margin: 1em auto;
	            width: 200px;
	            .blue-white-btn{
	               width: 100%;
	               @include fontSize(13px);
	            }
	        }
		}
	}
	#notificacion-solicitud-2{
		text-align: center;
	}
	@include susy-breakpoint($mobile, $susy){
		@include pad(1,1);
		@include span(10);
		&__item{
			@include span(12);
			margin: 1em 0;
			&__content{
				&:nth-child(10n+1){
					@include pad(0,0.25);
					@include span(4 of 12);
					margin: 0 0 2em;
				}
				&:nth-child(10n+2){
					@include pad(0,0);
					@include span(7.5 of 12);
					margin: 0 0 2em;
					.inputs-nacimiento{
						@include span(12);
						.custom-select{
							&:nth-child(10n+1){
								@include span(4);
							}
							&:nth-child(10n+2){
								@include span(4);
							}
							&:nth-child(10n+3){
								@include span(4);
							}
						}
					}
				}
				&:nth-child(10n+3){
					@include pad(0,0)
					@include span(7 of 12)
					margin: 0 0 2em;
					.inputs-nacimiento-lugar{
						@include span(12);
						.custom-select{
							&:nth-child(10n+1){
								@include span(6);
							}
							&:nth-child(10n+2){
								@include span(6);
							}
						}
					}
				}
				&:nth-child(10n+4){
					@include pad(0.5,0);
					@include span(4.5 of 12);
					margin: 0 0 2em;
				}
				&:nth-child(10n+5),
				&:nth-child(10n+7){
					@include pad(0,0.5);
					@include span(5.5 of 12);
					margin: 0 0 2em;
				}
				&:nth-child(10n+6),
				&:nth-child(10n+8){
					@include pad(0.5,0);
					@include span(5.5 of 12);
					margin: 0 0 2em;
				}
			}
		}
		&__buttons{
			@include pad(2,0)
			@include span(10);
			&__content{
				@include span(6 last of 12);
				.btn{
		            margin: 0;
		            float: right;
		        }
			}
		}
		#notificacion-solicitud-2{
			text-align: right;
		}
	}
	@include susy-breakpoint($tablet, $susy){
		@include pad(0.5,0.5);
		@include span(11);
		#notificacion-solicitud-1{
			text-align: right;
		}
		&__buttons{
			@include pad(3.5,0)
			@include span(8.5);
		}
	}
	@include susy-breakpoint($desktop, $susy){
		@include pad(1,1);
		@include span(10);
		&__item{
			&__content{
				&:nth-child(10n+1){
					@include pad(0,0.25);
					@include span(1.75 of 12);
				}
				&:nth-child(10n+2){
					@include pad(0,0);
					@include span(3 of 12);
					.inputs-nacimiento{
						@include span(12);
						.custom-select{
							&:nth-child(10n+1){
								@include span(4);
							}
							&:nth-child(10n+2){
								@include span(4);
							}
							&:nth-child(10n+3){
								@include span(4);
							}
						}
					}
				}
				&:nth-child(10n+3){
					@include pad(0.25,0)
					@include span(4 of 12)
					margin: 0 0 2em;
					.inputs-nacimiento-lugar{
						@include span(12);
						.custom-select{
							&:nth-child(10n+1){
								@include span(6);
							}
							&:nth-child(10n+2){
								@include span(6);
							}
						}
					}
				}
				&:nth-child(10n+4){
					@include pad(0.25,0);
					@include span(2.5 of 12);
					margin: 0 0 2em;
				}
				&:nth-child(10n+5){
					@include pad(0,0.25);
					@include span(2.75 of 12);
					margin: 0 0 2em;
				}
				&:nth-child(10n+6){
					@include pad(0.125,0.125);
					@include span(2.75 of 12);
					margin: 0 0 2em;
				}
				&:nth-child(10n+7){
					@include pad(0.125,0.125);
					@include span(2.75 of 12);
					margin: 0 0 2em;
				}
				&:nth-child(10n+8){
					@include pad(0.25,0);
					@include span(2.75 of 12);
					margin: 0 0 2em;
				}
			}
		}
		&__buttons{
			@include pad(4.5,0)
			@include span(7.5);
		}
	}
}
