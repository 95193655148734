.home-simulador{
   @include span(12);
   &__header{
      @include pad(0.5,0.5);
      @include span(11);
      @include fontSize(12.5px);
      h3{
         @include fontSize(18px);
         padding: 0.5em 0;
         text-transform: none;
      }
   }
   &__form{
      @include pad(0.5,0.5);
      @include span(11);
      margin: 1em 0 5em;
   }
   &__container{
      @include span(12);
   }
   &__col{
      @include span(12);
      &:nth-child(10n+3) {
         border-top: solid 1px $sky-blue;
         padding: 2em 0;
         .label-image-desktop {
            display: none;
         }
         .label-content-desktop{
            display: none;
         }
      }
   }
   &__row{
      @include span(12);
   }
   &__label{
      width: 100%;
      &__content{
         @include pad(2,2);
         @include span(8);
         margin: 2em 0;
         .label-image{
            @include span(3);
            img {
               width: 50px;
               height: 50px;
            }
         }
         .label_image_mobile{
            display: block;
         }
         .label-content{
            @include span(9);
            text-align: center;
         }
         .label_content_mobile{
            display: block;
         }
         .label-result{
            @include span(12);
            text-align: center;
            @include fontSize(20px);
            margin: 1em 0 0;
         }
      }
   }
   &__item{
      @include span(12);
      margin: 1em 0;
      .label-image {
         @include span(3);
         img {
            width: 50px;
            height: 50px;
         }
      }
      .label-content{
         @include span(9);
         h2{
            @include fontSize(20px);
         }
         h5{
            @include fontSize(13px);
            text-transform: none;
            color: $light-gray;
         }
      }
   }
   &__buttons{
      @include span(12);
      .btn{
         display: block;
         width: 220px;
         margin: 2em auto;
         text-align: center;
         .blue-white-btn,
         .orange-btn{
            width: 180px;
            margin: auto;
            @include fontSize(13px);
         }
      }
   }
   &__leyenda{
      @include span(12);
      margin: 1em 0 2em;
      h5{
         @include fontSize(12px);
         text-transform: none;
         color: $light-gray;
      }
   }
   &__error{
      @include span(12);
      margin: 1em 0;
      text-align: center;
   }
   .f-prestamo{
      @include fontSize(26px);
   }
   .input-finalidad{
      @include fontSize(18px);
      top: 10px;
   }
   .select-finalidad{
      @include fontSize(15px);
      margin-top: 13px;
   }
   .select-plazo{
      @include fontSize(20px);
      margin-top: 10px;
   }
   @include susy-breakpoint($mobile, $susy){
      &__header{
         @include pad(1,1);
         @include span(10);
      }
      &__form{
         @include pad(1,1);
         @include span(10);
      }
      &__col{
         @include span(12);
         &:nth-child(10n+3) {
            border-top: solid 1px $sky-blue;
            border-bottom: solid 1px $sky-blue;
            display: block;
            width: 100%;
            text-align: center;
            .label-image-desktop {
               display: inline-block;
               width: 50px;
               img {
                  width: 50px;
                  height: 50px;
               }
            }
            .label-content-desktop{
               display: inline-block;
               width: 215px;
               position: relative;
               top: -16px;
               h2{
                  @include fontSize(20px);
               }
            }
            .home-simulador__leyenda{
               margin: 1em 0 0;
               text-align: left;
            }
         }
      }
      &__label{
         display: inline-block;
         width: 180px;
         &__content{
            margin: 0;
            .label_image_mobile{
               display: none;
            }
            .label_content_mobile{
               display: none;
            }
            .label-result{
               margin: 0 0;
            }
         }
      }
      &__item{
         @include span(6);
         margin: 2em 0;
         .label-content{
            h2{
               @include fontSize(19px);
            }
         }
      }
      &__buttons{
         .btn{
            width: 240px;
            .blue-white-btn,
            .orange-btn{
               width: 200px;
            }
         }
      }
   }
   @include susy-breakpoint($tablet, $susy){
      &__col{
         @include span(12);
      }
      &__label{
         width: 200px;
      }
      &__item{
         @include span(6);
         .label-content{
            h2{
               @include fontSize(20px);
            }
         }
      }
      &__buttons{
         text-align: center;
         .btn{
            display: inline-block;
            margin: 2em 1em;
         }
      }
   }
   @include susy-breakpoint($desktop, $susy){
      &__col{
         @include span(6);
         &:nth-child(10n+2) {
            .home-simulador__item{
               @include pad(1,0);
               @include span(11);
               margin: 2em 0 1em;
               &:nth-child(10n+2) {
                  margin: 1.5em 0;
               }
               .label-image {
                  @include span(1.8);
               }
               .label-content{
                  @include span(10.2);
               }
            }
            .home-simulador__leyenda{
               @include pad(1,0);
               @include span(11);
               margin: 0em 0 2em;
            }
         }
         &:nth-child(10n+3) {
            .label-content-desktop{
               width: 210px;
            }
            .home-simulador__row{
               width: 50%;
               &:nth-child(10n+1){
                  padding: 0.5em 0 0;
               }
               &:nth-child(10n+2){
                  .home-simulador__leyenda{
                     margin: 0;
                     h5{
                        padding: 0 8.5%;

                     }
                  }
               }
            }
         }
         &:nth-child(10n+4) {
            width: 100%;
         }
      }
      &__label{
         width: 220px;
      }
      &__item{
         @include span(5.8);
      }
   }
}
