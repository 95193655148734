.center-container{
   position: relative;
}
.absolute-center{
   margin: auto;
   position: absolute;
   top: 0; left: 0; bottom: 0; right: 0;
}

.transfer{
	background-image: url('/images/transfer_img_mobile.png');
	background-size: cover;
    background-position: center;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 568px;
    color: $white;
    @include susy-breakpoint($mobile, $susy){
    	background-image: url('/images/transfer_img_desktop.png');
    	min-height: 320px;
    }
    @include susy-breakpoint($tablet, $susy){
    	background-image: url('/images/transfer_img_mobile.png');
    	min-height: 1024px;
    }
    @include susy-breakpoint(1024px, $susy){
    	background-image: url('/images/transfer_img_desktop.png');
    	min-height: 760px;
    }
    &__content{
    	height: 90%;
    	text-align: center;
    	@include susy-breakpoint($tablet, $susy){
    		height: 75%;
    	}
    	@include susy-breakpoint(1024px, $susy){
    		height: 75%;
    	}
    }
    &__logos{
    	@include span(12);
    	li{
    		@include span(12);
    		margin: 0 0 1em;
    		@include susy-breakpoint($mobile, $susy){
                &:nth-child(10n+1){
                    @include span(12);
                }
    			@include span(6);
                margin: 0 0 0;
    		}
            @include susy-breakpoint($tablet, $susy){
                margin: 0 0 1em;
            }
            @include susy-breakpoint(1024px, $susy){
                position: relative;
            }
    	}
    	&--prestanomico{
    		width: 220px;
    		@include susy-breakpoint($tablet, $susy){
    			width: 260px;
    		}
    		@include susy-breakpoint(1024px, $susy){
    			width: 320px;
    		}
    	}
    	&--transfer{
    		width: 120px;
            @include susy-breakpoint($tablet, $susy){
                width: 150px;
                margin-left: 8em;
            }
    		@include susy-breakpoint(1024px, $susy){
    			width: 150px;
                position: absolute;
                right: 4em;
    		}
    	}
        &--saldazo{
            width: 100px;
            @include susy-breakpoint($tablet, $susy){
                width: 130px;
                margin-right: 8em;
            }
            @include susy-breakpoint(1024px, $susy){
                width: 130px;
                position: absolute;
                left: 5em;
            }
        }
    }
    p{
    	@include fontSize(12px);
    	@include susy-breakpoint($tablet, $susy){
    		@include fontSize(15px);
    	}
    }
    &__text{
    	@include span(12);
    	margin: 0 0 0;
    	@include susy-breakpoint($mobile, $susy){
    		margin: 0;
    	}
    	@include susy-breakpoint($tablet, $susy){
    		margin: 5em 0;
    	}
    	@include susy-breakpoint(1024px, $susy){
    		margin: 5em 0 2em;
    	}
    	h1{
    		width: 295px;
    		margin: auto;
    		@include fontSize(24px);
    		.cursive{
    			@include fontSize(30px);
    		}
    		@include susy-breakpoint($tablet, $susy){
    			@include fontSize(28px);
    			.cursive{
    				@include fontSize(36px);
    			}
    		}
    		@include susy-breakpoint(1024px, $susy){
    			width: 350px;
    			@include fontSize(34px);
    			.cursive{
    				@include fontSize(42px);
    			}
    		}
    	}
    	p{
    		width: 280px;
    		margin: 2em auto 4em;
    		@include susy-breakpoint($mobile, $susy){
    			width: 515px;
    			margin: 0.5em auto 1.25em;
    		}
    		@include susy-breakpoint($tablet, $susy){
    			width: 670px;
    			margin: 3em auto 3em;
    		}
    	}
    }
    .orange-btn{
    	@include fontSize(13px);
    	padding: 0.8em 1.2em;
      display: inline-block;
      cursor: pointer;
    	@include susy-breakpoint($tablet, $susy){
    		@include fontSize(15px);
    	}
    }
    &__disclaimer{
    	width: 100%;
    	position: absolute;
    	bottom: 10%;
    	left: 0;
    	text-align: center;
    	p{
    		padding: 0;
    	}
    	@include susy-breakpoint($mobile, $susy){
    		bottom: 5%;
    	}
    	@include susy-breakpoint($tablet, $susy){
    		bottom: 20%;
    	}
    	@include susy-breakpoint(1024px, $susy){
    		bottom: 10%;
    	}
    }
}
