@font-face {
   font-family: 'Montserrat-Bold';
   src: url("../Fonts/Montserrat/Montserrat-Bold.ttf");
   src: url("../Fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
   font-family: 'Montserrat-Regular';
   src: url("../Fonts/Montserrat/Montserrat-Regular.ttf");
   src: url("../Fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
   font-family: 'oliver';
   src: url("../Fonts/olivier.ttf");
   src: url("../Fonts/olivier.ttf") format("truetype");
}

@font-face{
   font-family: 'LeckerliOne-Regular';
   src: url("../Fonts/LeckerliOne-Regular.otf");
   src: url("../Fonts/LeckerliOne-Regular.otf") format("otf");
   src: local('LeckerliOne-Regular'), url("../Fonts/LeckerliOne-Regular.otf") format("opentype");
}

html, body, #wrap {
   height: 100%;
}

body {
   float: left;
   margin: 0;
   width: 100%;
   font-family: 'Montserrat-Regular';
   overflow-x: hidden;
}

body > #wrap {
   height: auto;
   min-height: 100%;
}
