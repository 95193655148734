.login-popup{
	background: $white;
    padding: 20px 30px;
    text-align: center;
    max-width: 290px;
    margin: 40px auto;
    position: relative;
    @include susy-breakpoint(1024px, $susy) {
    	 padding: 35px 75px;
    }
    h2{
    	@include fontSize(24px);
    	font-family: 'LeckerliOne-Regular';
   		text-transform: none;
   		@include susy-breakpoint($desktop, $susy) {
   			@include fontSize(28px);
   		}
    }
    &__link{
    	display: block;
    	color: $black;
    	text-decoration: underline;
    	margin: 2em auto;
    	@include fontSize(11px);
    }
    .orange-btn{
    	@include fontSize(13px);
    	padding: 0.8em 4em;
    	margin: 1.5em 0 0.5em;
    }
    .icon-inline{
    	width: 15px;
    	display: inline-block;
    	position: relative;
    	top: 0.2em;
    	left: 0.5em;
    }
    .my-input{
    	margin: 1em 0 0;
    	input{
    		width: 98%;
    		top: 15px;
    		left: 3px;
    		z-index: 1;
    		@include fontSize(13px);
    	}
    	.linea-dato{
    		height: 20px;
    		border: solid 1px $gray;
    		border-top: none;
    		z-index: 0;
    	}
    }
    .msg-error{
    	text-align: left;
    }
    .leyenda{
    	@include fontSize(12px);
    	margin: 2.5em 0 1.5em; 
    }
    #allError,
    #aError{
        text-align: center;
        margin: 1em 0;
    }
}