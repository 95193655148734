select,
input[type="text"],
input[type="tel"] {
   @include appearance;
   @include box-shadow(none);
   @include border-radius(none);
   border: none;
   font-family: 'Montserrat-Regular';
   color: $black;
   text-transform: uppercase;
   background: transparent;
   &:focus {
      outline: none;
   }
}
//color placeholders
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $light-gray;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $light-gray;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $light-gray;
}
:-moz-placeholder { /* Firefox 18- */
  color: $light-gray;
}

input[type="text"]:disabled {
   @include appearance;
   color: $white;
   -webkit-text-fill-color: $white;
}

input[type="password"] {
   @include appearance;
   @include box-shadow(none);
   @include border-radius(none);
   border: none;
   font-family: 'Montserrat-Regular';
   &:focus {
      outline: none;
   }
}

.linea-dato{
   width: 100%;
   height: 10px;
   border: solid 1px $sky-blue;
   border-top: none;
   position: absolute;
   bottom: 5px;
   left: 0;
}

.my-input{
   @include span(12);
   position: relative;
   height: 45px;
   input{
      width: 100%;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      @include fontSize(14px);
   }
}

.my-select{
   width: 100%;
   position: relative;
   height: 40px;
   select{
      width: 100%;
      text-align-last: center;
      @include fontSize(22px);
      position: absolute;
      top: 3px;
      left: 0;
   }
   .select-finalidad{
      @include fontSize(16px);
      top: 10px;
   }
}

.my-label{
   @include span(12);
   position: relative;
   .line-up{
      @include span(12);
      height: 10px;
      border: solid 1px $sky-blue;
      border-bottom: none;
   }
   .line-down{
      @include span(12);
      height: 10px;
      border: solid 1px $sky-blue;
      border-top: none;
   }
}

.label{
   @include span(12);
   position: relative;
   label{
      @include span(12);
      color: $light-gray;
      @include fontSize(14px);
   }
}

.custom-textarea {
   @include span(12);
   border: solid 1px $sky-blue;
   textarea {
      @include pad(1,1);
      @include span(10);
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      @include appearance;
      @include box-shadow(none);
      @include border-radius(none);
      font-family: 'Montserrat-Regular';
      font-style: italic;
      color: $black;
      background: transparent;
      resize: none;
      border: none;
      @include fontSize(12px);
      &:focus {
         outline: none;
      }
   }
}

.form-data{
   @include span(12);
   height: 40px;
   &__label{
      @include span(6 of 12);
      height: 100%;
      position: relative;
      &--line-up{
         width: 100%;
         height: 10px;
         position: absolute;
         top: 0;
         left: 0;
         @include box-shadow(inset 1px 1px $sky-blue);
      }
      &--line-down{
         width: 100%;
         height: 10px;
         position: absolute;
         bottom: 0;
         left: 0;
         @include box-shadow(inset 1px -1px 0 0 $sky-blue);
      }
      .label{
         @include span(12);
         margin: 11px 0;
         color: $black;
         text-align: center;
      }
   }
   &__input{
      @include span(6 last of 12);
      height: 100%;
      background-color: $sky-blue;
      input{
         @include span(12);
         background-color: transparent;
         color: $white;
         text-align: center;
         margin: 10px 0;
      }
   }
   input,
   .label{
      @include fontSize(13px);
      text-transform: uppercase;
   }
}

/*conoce-y-ahorra*/
.bg-orange{
   background-color: $orange;
   border-right: solid 2px $orange;
}
.bg-sky-blue{
   background-color: $sky-blue;
   border-right: solid 2px $sky-blue;
}
.simu-label{
   @include span(12);
   text-align: center;
   padding: 0.75em 0;
   border-radius: 10px 10px 0 0;
   label{
      @include span(12);
      color: $white;
      @include fontSize(18px);
      text-transform: uppercase;
      p {
         @include fontSize(18px);
      }
   }
}
.simu-tag{
   @include span(12);
   background-color: $white;
   text-align: center;
   padding: 0.75em 0;
   p{
      @include span(12);
      color: $light-gray;
      @include fontSize(12px);
   }
}
.simu-my-input{
   @include span(12);
   position: relative;
   height: 60px;
   text-align: center;
   .simu-input{
      width: 100%;
      text-align: center;
      position: absolute;
      top: 15px;
      left: 0;
      @include fontSize(28px);
   }
   .border-sky-blue{
      border: solid 1px $sky-blue;
   }
   .border-orange{
      border: solid 1px $orange;
   }
   .simu-linea-dato{
      width: 100%;
      height: 10px;
      border-top: none;
      position: absolute;
      bottom: 5px;
      left: 0;
   }
   strong{
      p{
         @include fontSize(24px);
         padding: 0.7em 0 0;
         display: inline-block;
      }
   }
   #plazos{
      @include fontSize(24px);
   }
}

.simu-result{
   @include span(12);
   .border-sky-blue{
      @include span(12);
      border: solid 1px $sky-blue;
   }
   .border-orange{
      @include span(12);
      border: solid 1px $orange;
   }
   &__tag{
      @include span(12);
      background-color: $white;
      text-align: center;
      padding: 0.75em 0;
      p{
         @include span(12);
         color: $light-gray;
         @include fontSize(12px);
      }
   }
   &__result{
      @include span(12);
      text-align: center;
      height: 60px;
      strong{
         @include fontSize(24px);
         p{
            @include fontSize(24px);
            padding: 0.7em 0 0;
            display: inline-block;
         }
      }
      label{
         @include fontSize(24px);
      }
   }
}
/*End conoce-y-ahorra*/

.msg-error{
   @include span(12);
   color: $red;
   @include fontSize(12px);
   margin: 0.5em 0;
}
.leyenda{
   @include span(12);
   color: $black;
   @include fontSize(11px);
   margin: 0.5em 0;
}

/*radio inputs*/
.radio-input{
   position: relative;
   & input{
      position: absolute;
      visibility: hidden;
   }
   &__label{
      & .radio{
         cursor: pointer;
         position: relative;
         width: 18px;
         height: 18px;
         border: 1px solid $navy-blue;
         display: inline-block;
         border-radius: 100%;
         top: 4px;
      }
   }
}

input[type="radio"]:checked ~ label.radio-input__label{
   & .radio{
      &:before{
         content: '';
         display: block;
         position: absolute;
         width: 12px;
         height: 12px;
         top: 3px;
         left: 3px;
         border-radius: 100%;
         background-color: $navy-blue;
      }
   }
}

.my-checkbox {
   @include span(12);
   height: 30px;
   color: $black;
   label {
      @include span(6);
      color: $black;
      height: 30px;
      text-transform: none;
   }
}
/*end radio inputs*/

/*tooltips*/
.tooltip {
    width: 20px;
    height: 20px;
    position: absolute;
    display: inline-block;
    &:hover {
        cursor: pointer;
    }
    .tooltip-img {
        width: 20px;
        height: 20px;
    }
}
.tooltip .tooltip-text {
    visibility: hidden;
    background-color: $gray;
    border: solid 1px $gray;
    color: $white;
    text-align: left;
    position: absolute;
    z-index: 1;
    padding: 2em 1em;
    @include fontSize(12px);
    ul{
      li{
         padding: 0.5em 0;
      }
    }
}

.tooltip .tooltip-text::before,
.tooltip .tooltip-text:after{
   content: '';
   display: block;
   position: absolute;
   left: -21px;
   width: 0;
   height: 0;
   border-style: solid;
}
/* this border color controlls the color of the triangle (what looks like the fill of the triangle) */
.tooltip .tooltip-text:after {
   top: 0;
   border-color: transparent transparent transparent transparent;
   border-width: 10px;
}
/* this border color controlls the outside, thin border */
.tooltip .tooltip-text:before {
   top: 65%;
   border-color: transparent $gray transparent transparent;
   border-width: 10px;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
}

.tooltip-pass{
   top: 0;
   left: 98px;
}
.tooltip-pass .tooltip-text{
   top: -135px;
   left: -18px;
   width: 180px;
   @include susy-breakpoint($mobile, $susy){
      left: -170px;
   }
    @include susy-breakpoint($desktop, $susy){
      left: -18px;
   }
}
.tooltip-pass .tooltip-text:before {
   top: 100%;
   left: 8%;
   border-color: $gray transparent transparent transparent;
   @include susy-breakpoint($mobile, $susy){
      left: 82%;
   }
   @include susy-breakpoint($desktop, $susy){
      left: 8%;
   }
}
.tooltip-rfc{
   top: -3px;
   left: 40px;
}
.tooltip-rfc .tooltip-text{
   top: -80px;
   left: 35px;
   width: 180px;
}
.tooltip-curp{
   top: -3px;
   left: 53px;
}
.tooltip-curp .tooltip-text{
   top: -130px;
   left: 35px;
   width: 180px;
   @include susy-breakpoint($mobile, $susy){
      top: -160px;
      left: 32px;
      width: 150px;
   }
   @include susy-breakpoint($tablet, $susy){
      top: -95px;
      left: 32px;
      width: 230px;
   }
   @include susy-breakpoint($desktop, $susy){
      top: -112px;
      width: 205px;
   }
}
.tooltip-curp .tooltip-text:before {
   top: 76%;
   @include susy-breakpoint($mobile, $susy){
      top: 80%;
   }
   @include susy-breakpoint($tablet, $susy){
      top: 70%;
   }
   @include susy-breakpoint($desktop, $susy){
      top: 73%;
   }
}
.tooltip-soli3{
   display: block;
   margin-top: 5px;
}
.tooltip-soli3 .tooltip-text{
   top: -100px;
   left: 32px;
   width: 240px;
   @include susy-breakpoint($tablet, $susy){
      top: -80px;
      width: 340px;
   }
}
.tooltip-soli3-1{
   display: block;
   margin-top: 5px;
}
.tooltip-soli3-1 .tooltip-text{
   top: -113px;
   left: 32px;
   width: 240px;
   @include susy-breakpoint($tablet, $susy){
      top: -98px;
      width: 340px;
   }
}
.tooltip-soli3-1 .tooltip-text:before {
   top: 82%;
   @include susy-breakpoint($tablet, $susy){
      top: 80%;
   }
}
.tooltip-soli3-2{
   display: block;
   margin-top: 5px;
}
.tooltip-soli3-2 .tooltip-text{
   top: -160px;
   left: 32px;
   width: 240px;
   @include susy-breakpoint($mobile, $susy){
      top: -210px;
      width: 200px;
   }
   @include susy-breakpoint($tablet, $susy){
      top: -145px;
      width: 295px;
   }
   @include susy-breakpoint($desktop, $susy){
      top: -130px;
      width: 340px;
   }
}
.tooltip-soli3-2 .tooltip-text:before {
   top: 87%;
   @include susy-breakpoint($mobile, $susy){
      top: 90%;
   }
   @include susy-breakpoint($tablet, $susy){
      top: 85%;
   }
}
.tooltip-soli3-3{
   display: block;
   margin-top: 5px;
}
.tooltip-soli3-3 .tooltip-text{
   top: -282px;
   left: 32px;
   width: 240px;
   @include susy-breakpoint($tablet, $susy){
      top: -268px;
      width: 295px;
   }
   @include susy-breakpoint($desktop, $susy){
      top: -187px;
      width: 395px;
   }
}
.tooltip-soli3-3 .tooltip-text:before {
   top: 92%;
   @include susy-breakpoint($desktop, $susy){
      top: 89%;
   }
}
.tooltip-soli3-4{
   display: block;
   margin-top: 5px;
}
.tooltip-soli3-4 .tooltip-text{
   top: -362px;
   left: 32px;
   width: 240px;
   @include susy-breakpoint($mobile, $susy){
      top: -380px;
      width: 200px;
   }
   @include susy-breakpoint($tablet, $susy){
      top: -312px;
      width: 295px;
   }
   @include susy-breakpoint($desktop, $susy){
      top: -328px;
      width: 260px;
   }
}
.tooltip-soli3-4 .tooltip-text:before {
   top: 94%;
   @include susy-breakpoint($mobile, $susy){
      top: 95%;
   }
   @include susy-breakpoint($tablet, $susy){
      top: 93%;
   }
}
.tooltip-autorizo{
   display: block;
   margin-top: 5px;
}
.tooltip-autorizo .tooltip-text{
   top: -355px;
   left: 32px;
   width: 240px;
   @include susy-breakpoint($mobile, $susy){
      top: -215px;
      width: 400px;
   }
}
.tooltip-autorizo .tooltip-text:before {
   top: 94%;
   @include susy-breakpoint($mobile, $susy){
      top: 91.8%;
   }
}
.tooltip-ingreso{
   top: 18px;
   left: 115px;
}
.tooltip-ingreso .tooltip-text{
   top: -238px;
   left: -116px;
   width: 267px;
   @include susy-breakpoint($mobile, $susy){
      top: -150px;
      left: 35px;
      width: 315px;
   }
   @include susy-breakpoint($tablet, $susy){
      top: -130px;
      left: -412px;
      width: 370px;
   }
}
/* this border color controlls the outside, thin border */
.tooltip-ingreso .tooltip-text:before {
   top: 100%;
   left: 39%;
   border-color: $gray transparent transparent transparent;
   @include susy-breakpoint($mobile, $susy){
      top: 81%;
      left: -21px;
      border-color: transparent $gray transparent transparent;
   }
   @include susy-breakpoint($tablet, $susy){
      top: 78%;
      left: 100%;
      border-color: transparent transparent transparent $gray;
   }
}
.tooltip-gastos{
   top: 18px;
   left: 95px;
}
.tooltip-gastos .tooltip-text{
   top: -365px;
   left: -95px;
   width: 267px;
   @include susy-breakpoint($mobile, $susy){
      top: -275px;
      left: -358px;
      width: 315px;
   }
   @include susy-breakpoint($tablet, $susy){
      top: -245px;
      left: -365px;
      width: 325px;
   }
   @include susy-breakpoint($desktop, $susy){
      top: -246px;
      left: -412px;
      width: 370px;
   }
}
/* this border color controlls the outside, thin border */
.tooltip-gastos .tooltip-text:before {
   top: 100%;
   left: 32%;
   border-color: $gray transparent transparent transparent;
   @include susy-breakpoint($mobile, $susy){
      top: 88%;
      left: 100%;
      border-color: transparent transparent transparent $gray;
   }
}
/*end tooltips*/
