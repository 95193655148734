.orange-btn {
   background-color: $orange;
   border: none;
   border-radius: 5px;
   color: $white;
   text-transform: uppercase;
   padding: 0.8em;
   opacity: 1;
   transition: all .2s ease-in-out;
   &:hover {
      background-color: $yellow-brown;
      color: $white;
   }
}

.blue-white-btn {
   background: $white;
   border: solid 1px $sky-blue;
   border-radius: 5px;
   color: $sky-blue;
   text-transform: uppercase;
   padding: 0.8em;
   transition: all .2s ease-in-out;
   &:hover {
      background: $navy-blue;
      color: $white;
      a{
         color: $white;
      }
   }
   a{
      color: $sky-blue;
      &:hover{
         color: $white;
      }
   }
}

.step-btn {
   border-radius: 5px;
}

.aprovado {
   background-color: $yellow;
   position: relative;
   top: -10px;
   z-index: 2;
   @include span(12)
   height: 40px;
   .aprovado-img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      margin-left: -15px;
      margin-top: -15px;
   }
}
.denegado {
   background-color: $red;
   position: relative;
   top: -10px;
   z-index: 2;
   @include span(12)
   height: 40px;
   .denegado-img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      margin-left: -15px;
      margin-top: -15px;
   }
}

.gray-btn {
   background: $light-gray;
   border: solid 1px $light-gray;
   border-radius: 5px;
   color: $white;
   text-transform: uppercase;
   padding: 0.8em;
   &:hover {
      background: $gray;
      color: $white;
   }
}
