.modificar-solicitud {
   @include span(12);
   margin: 1em 0;
   &__form{
      @include pad(0.5,0.5);
      @include span(11);
      @include fontSize(12.5px);
   }
   &__col{
      @include span(12);
      padding: 0.5em 0;
      &--container{
         @include span(12)
      }
   }
   @include susy-breakpoint($mobile, $susy) {
      &__form{
         @include pad(1,1);
         @include span(10);
      }
      &__col{
         @include span(12);
      }
   }
   @include susy-breakpoint($tablet, $susy) {
      &__form{
         @include pad(0.5,0.5);
         @include span(11);
      }
      &__col{
         @include span(4);
         &--container{
            @include pad(0,0);
            @include span(12);
         }
      }
   }
   @include susy-breakpoint($desktop, $susy) {
      &__form{
         @include pad(1,1);
         @include span(10);
      }
      &__col{
         @include span(4);
         &:nth-child(10n+1){
            .modificar-solicitud__col--container{
               @include pad(0,1);
               @include span(11);
            }
         }
         &:nth-child(10n+2){
            .modificar-solicitud__col--container{
               @include pad(0.5,0.5);
               @include span(11);
            }
         }
         &:nth-child(10n+3){
            .modificar-solicitud__col--container{
               @include pad(1,0);
               @include span(11);
            }
         }
      }
   }
}