@import './bower_components/susy/sass/_susy.scss';

$susy: (
  container: auto,
  columns: 12,
  gutters: 0
);

$breakpoint-no-query-fallbacks: true;

/*$phone   :      0;
$mobile  :  560px;
$tablet  :  768px;
$desktop :  1024px;*/

$phone      :      0;
$mobile     :  568px;
$tablet     :  768px;
$desktop    : 1200px;
