.first-block{
   position: relative;
   width: 100%;
   height: 100%;
   min-height: 568px;
   color: $white;
   h1{

      @include fontSize(25px);
   }
   h2{

      @include fontSize(25px);
      margin: 0 0 1em;
   }
   .cursive{
      @include fontSize(36px);
      display: block;
      @include susy-breakpoint($mobile, $susy) {
         display: inline-block;
      }
   }
   p{
      @include fontSize(14px);
      margin: 1em 0 0;
   }
   .orange-btn{
      display: inline-block;
      @include fontSize(12px);
      margin: 3em 0;
      padding: 0.6em 0.8em;
      @include susy-breakpoint($tablet, $susy) {
         padding: 0.8em 1em;
         @include fontSize(13px);
      }
   }
   @include susy-breakpoint($mobile, $susy) {
      height: 90%;
      min-height: 320px;
   }
   @include susy-breakpoint($desktop, $susy) {
      min-height: 480px;
   }
}

.center-container{
   position: relative;
}
.absolute-center{
   /*overflow: auto;*/
   margin: auto;
   position: absolute;
   top: 0; left: 0; bottom: 0; right: 0;
}

.home-slider{
   position: relative;
   width: 100%;
   height: 100%;
   overflow: hidden;
   & .slide{
      background-size: cover;
      background-position: center;
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      &__container{
         height: 100%;
         text-align: center;
      }
      &__h1 {
        text-transform: none;
      }
      &__img {
        width: 100%;
        max-width: 280px;
        margin: 1em 0;
      }
   }
   .slide-1 {
      background-image: url('/images/Foto_mobile_a.png');
      .absolute-center{
         width: 240px;
      }
   }
   .slide-2 {
      background-image: url('/images/Foto_mobile_c.png');
      .absolute-center{
         width: 250px;
      }
   }
   .slide-3 {
      background-image: url('/images/Foto_mobile_b.png');
      .absolute-center{
         width: 240px;
      }
   }
   .slide-4 {
      background-image: url('/images/Foto_mobile_d.png');
      .absolute-center{
         width: 240px;
      }
   }
   .absolute-center{
      height: 68%;
   }
   @include susy-breakpoint($mobile, $susy) {
      h2{
         margin: 0;
      }
      .absolute-center{
         height: 65%;
      }
      .orange-btn{
         margin: 2em 0 0;
      }
      .slide-1 {
         background-image: url('/images/Foto_a.png');
         .absolute-center{
            width: 450px;
         }
      }
      .slide-2 {
         background-image: url('/images/Foto_c.png');
         .absolute-center{
            width: 410px;
         }
         .cursive{
            display: block;
         }
      }
      .slide-3 {
         background-image: url('/images/Foto_b.png');
         .absolute-center{
            width: 340px;
         }
      }
      .slide-4 {
         background-image: url('/images/Foto_d.png');
         .absolute-center{
            width: 340px;
         }
         .cursive{
            display: block;
         }
      }
   }
   @include susy-breakpoint($tablet, $susy) {
      h1{
         @include fontSize(28px);
      }
      h2{
         @include fontSize(28px);
      }
      .cursive{
         @include fontSize(38px);
      }
      .absolute-center{
         height: 46%;
      }
      .orange-btn{
         margin: 5em 0 0;
      }
      & .slide{
        &__img {
          max-width: 480px;
        }
      }
      .slide-1 {
         .cursive{
            display: block;
         }
      }
      .slide-2 {
         .cursive{
            display: block;
         }
      }
      .slide-3 {
         .absolute-center{
            width: 340px;
         }
      }
      .slide-4 {
         .absolute-center{
            width: 220px;
            p{
               margin: 2em 0 0;
            }
            .orange-btn {
               margin: 4em  0 0;
            }
         }
      }
   }
   @include susy-breakpoint($desktop, $susy) {
      h1{
         @include fontSize(34px);
      }
      h2{
         @include fontSize(34px);
      }
      .cursive{
         @include fontSize(44px);
      }
      p{
         @include fontSize(15px);
      }
      .slide-1 {
         .absolute-center{
            width: 620px;
         }
      }
      .slide-2 {
         .absolute-center{
            width: 625px;
         }
      }
      .slide-3 {
         .absolute-center{
            width: 610px;
         }
      }
      .slide-4 {
         .absolute-center{
            width: 500px;
            p{
               margin: 1em 0 0;
            }
            .orange-btn {
               margin: 4em  0 0;
            }
         }
      }
   }
}

.registro-block {
   @include span(12);
   position: relative;
   background-color: $white;
   .pagination {
      @include span(12);
      margin-bottom: 10%;
      .p-left {
         border: solid 1px $sky-blue;
         border-right: none;
         width: 6px;
         height: 20px;
         display: inline-block;
      }
      .p-center {
         width: 45px;
         height: 22px;
         display: inline-block;
         position: relative;
         p {
            position: absolute;
            top: 3.5px;
            left: 5px;
            color: $light-gray;
            font-size: 0.8rem;
         }
      }
      .p-right {
         border: solid 1px $sky-blue;
         border-left: none;
         width: 6px;
         height: 20px;
         display: inline-block;
      }
   }
   .arrow-opened{
      background-size: cover;
      background-position: center;
      position: relative;
      @include span(12);
      background-image: url('/images/flecha_arriba.png');
      height: 16px;
      @include susy-breakpoint($mobile, $susy) {
         height: 20px;
      }
      @include susy-breakpoint($tablet, $susy) {
         height: 25px;
      }
      @include susy-breakpoint(1024px, $susy) {
         height: 34px;
      }
   }
}

.como-funciona{
   background: $white;
   position: relative;
   width: 100%;
   height: auto;
   overflow: hidden;
   &__head{
      @include pad(1,1);
      @include span(10);
      @include susy-breakpoint($tablet, $susy) {
         @include pad(0.5,0.5);
         @include span(11);
      }
      @include susy-breakpoint($desktop, $susy) {
         @include pad(1,1);
         @include span(10);
      }
   }
   &__container{
      @include pad(1,1);
      @include span(10);
      margin-bottom: 3em;
      @include susy-breakpoint($mobile, $susy) {
         @include pad(3,3);
         @include span(6);
      }
      @include susy-breakpoint($tablet, $susy) {
         @include pad(0.5,0.5);
         @include span(11);
      }
      @include susy-breakpoint($desktop, $susy) {
         @include pad(1,1);
         @include span(10);
      }
   }
   h3{
      @include fontSize(17px);
      text-transform: none;
      margin: 2em 0;
   }
   .step{
      @include span(12);
      &:nth-child(10n+1){
         .step__container{
            background-color: #2790ce;
            .text{
               .absolute-center{
                  height: 125px;
                  @include susy-breakpoint($tablet, $susy) {
                     height: 110px;
                  }
                  @include susy-breakpoint($desktop, $susy) {
                     height: 105px;
                  }
               }
            }
            h5, ul{
               width: 165px;
               margin: auto;
               @include susy-breakpoint($tablet, $susy) {
                  width: 150px;
               }
               @include susy-breakpoint($desktop, $susy) {
                  width: 165px;
               }
            }
         }
      }
      &:nth-child(10n+2){
         .step__container{
            background-color: #44b4f6;
            .text{
               .absolute-center{
                  height: 125px;
                  @include susy-breakpoint($tablet, $susy) {
                     height: 110px;
                  }
                  @include susy-breakpoint($desktop, $susy) {
                     height: 105px;
                  }
               }
            }
            h5, ul{
               width: 200px;
               @include susy-breakpoint($tablet, $susy) {
                  width: 168px;
               }
               @include susy-breakpoint($desktop, $susy) {
                  width: 201px;
               }
            }
         }
      }
      &:nth-child(10n+3){
         .step__container{
            background-color: #7bc8f6;
            .step-btn{
               @include susy-breakpoint($desktop, $susy) {
                  @include span(2 of 12);
               }
            }
            .text{
               @include susy-breakpoint($desktop, $susy) {
                  @include span(10 of 12);
               }
               .absolute-center{
                  height: 125px;
                  @include susy-breakpoint($tablet, $susy) {
                     height: 110px;
                  }
                  @include susy-breakpoint($desktop, $susy) {
                     height: 105px;
                  }
               }
            }
            h5, ul{
               width: 190px;
               @include susy-breakpoint($tablet, $susy) {
                  width: 150px;
               }
               @include susy-breakpoint($desktop, $susy) {
                  width: 194px;
               }
            }
         }
      }
      @include susy-breakpoint($tablet, $susy) {
         @include pad(0,0.25);
         @include span(3.75);
         &:nth-child(10n+2){
            @include pad(0,0);
            @include span(4);
         }
         &:nth-child(10n+3){
            @include pad(0.25,0);
            @include span(3.75);
         }
      }
      @include susy-breakpoint($desktop, $susy) {
         @include pad(0,0.25);
         @include span(3.75);
         &:nth-child(10n+2){
            @include pad(0.125,0.125);
            @include span(3.75);
         }
         &:nth-child(10n+3){
            @include pad(0.25,0);
            @include span(3.75);
         }
      }
      margin: 0 0 2em;
      h2{
         @include fontSize(18px);
         @include susy-breakpoint($tablet, $susy) {
            @include fontSize(16px);
         }
      }
      &__container{
         @include pad(0,0);
         @include span(12);
         border-radius: 5px;
         height: 150px;
         color: $white;
         .step-btn{
            @include span(3 of 12);
            @include susy-breakpoint($desktop, $susy) {
               @include span(3 of 12);
            }
            height: 100%;
            .icon-elige{
               width: 45px;
               height: 45px;
               padding-left: 40%;
               @include susy-breakpoint($tablet, $susy) {
                  padding-left: 35%;
               }
               @include susy-breakpoint(1024px, $susy) {
                  padding-left: 55%;
               }
               @include susy-breakpoint($desktop, $susy) {
                  padding-left: 65%;
               }
            }
            .icon-carga{
               width: 45px;
               height: 40px;
               padding-left: 25%;
               @include susy-breakpoint($tablet, $susy) {
                  padding-left: 30%;
               }
               @include susy-breakpoint(1024px, $susy) {
                  padding-left: 50%;
               }
                @include susy-breakpoint($desktop, $susy) {
                  padding-left: 55%;
               }
            }
            .icon-recibe{
               width: 25px;
               height: 50px;
               padding-left: 40%;
               @include susy-breakpoint($tablet, $susy) {
                  padding-left: 50%;
               }
               @include susy-breakpoint(1024px, $susy) {
                  padding-left: 60%;
               }
               @include susy-breakpoint($desktop, $susy) {
                  padding-left: 80%;
               }
            }
         }
         .text{
            @include span(9 of 12);
            @include susy-breakpoint($desktop, $susy) {
               @include span(9 of 12);
            }
            height: 100%;
            text-align: center;
            h2{
               width: 175px;
               margin: auto;
               padding-bottom: 0.5em;
               @include susy-breakpoint($tablet, $susy) {
                  width: 160px;
               }
            }
            h5, ul{
               @include fontSize(11px);
               text-transform: none;
               color: $white;
               margin: auto;
            }
         }
      }
   }
}

.historias {
   @include span(12);
   background-color: $white;
   position: relative;
   &__arrow-closed{
      background-size: cover;
      background-position: center;
      position: relative;
      @include span(12);
      background-image: url('/images/flecha_abajo.png');
      height: 16px;
      margin-bottom: 1.5em;
      @include susy-breakpoint($mobile, $susy) {
         height: 20px;
      }
      @include susy-breakpoint($tablet, $susy) {
         height: 25px;
      }
      @include susy-breakpoint(1024px, $susy) {
         height: 34px;
      }
   }
   &__text{
      @include pad(1,1);
      @include span(10);
      @include susy-breakpoint($tablet, $susy) {
         @include pad(0.5,0.5);
         @include span(11);
      }
      @include susy-breakpoint($desktop, $susy) {
         @include pad(1,1);
         @include span(10);
      }
   }
   &__title{
      @include fontSize(17px);
      text-transform: none;
      margin: 0.5em 0;
   }
   &__subtitle{
      @include fontSize(13px);
   }
   &__video {
      @include span(12);
      margin: 2em auto;
      min-height: 200px;
      max-height: 280px;
      @include susy-breakpoint($mobile, $susy) {
         min-height: 260px;
      }
      @include susy-breakpoint($tablet, $susy) {
         min-height: 290px;
      }
      @include susy-breakpoint($desktop, $susy) {
         min-height: 420px;
      }
   }
   .videoBox{
      width: 100%;
      margin: 0 auto;
      position: relative;
      @include susy-breakpoint($mobile, $susy) {
         width: 70%;
      }
      @include susy-breakpoint($tablet, $susy) {
         width: 60%;
         max-width: 520px;
      }
      @include susy-breakpoint($desktop, $susy) {
         width: 60%;
         max-width: 680px;
      }
   }
   .imgLap{
      width: 100%;
      position: absolute;
   }
   .videoContainer{
      padding-bottom:56.25%;
      height:0;
      overflow:hidden;
      top: -50%;
   }
   .videoContainer iframe{
      position: absolute;
      top: 5.3%;
      left: 14.9%;
      width: 72.2%;
      height: 80%;
   }
}

.testimoniales {
   @include span(12);
   background-color: $white;
   position: relative;
   &__text{
      @include pad(1,1);
      @include span(10);
      @include susy-breakpoint($tablet, $susy) {
         @include pad(0.5,0.5);
         @include span(11);
      }
      @include susy-breakpoint($desktop, $susy) {
         @include pad(1,1);
         @include span(10);
      }
   }
   &__title{
      @include fontSize(17px);
      text-transform: none;
      margin: 0.5em 0;
   }
   &__subtitle{
      @include fontSize(13px);
   }
   &__disc {
      @include pad(1,1);
      @include span(10);
      text-align: center;
      margin-bottom: 3em;
      @include fontSize(13px);
      a {
         text-decoration: underline;
         color: $black;
      }
   }
}

.cards {
   @include span(12);
   background-color: $white;
   position: relative;
   margin: 2em 0;
   &__container{
      @include pad(1,1);
      @include span(10);
      @include susy-breakpoint($mobile, $susy) {
         @include pad(2.5,2.5);
         @include span(7);
      }
      @include susy-breakpoint($tablet, $susy) {
         @include pad(0.5,0.5);
         @include span(11);
      }
      @include susy-breakpoint($desktop, $susy) {
         @include pad(1,1);
         @include span(10);
      }
   }
   .card{
      @include span(12);
      margin-bottom: 2em;
      &__container{
         @include span(12);
         position: relative;
         @include susy-breakpoint($tablet, $susy) {
            @include span(10);
            @include pad(1,1);
         }
         @include susy-breakpoint($desktop, $susy) {
            @include span(10);
            @include pad(1,1);
         }
      }
      @include susy-breakpoint($tablet, $susy) {
         @include span(3);
      }
      @include susy-breakpoint($desktop, $susy) {
         @include span(3);
      }
   }
   .card-body {
      cursor: default;
      position: relative;
      @include span(12);
      background-color: #ef9738;
      opacity: 0.9;
      @include susy-breakpoint($tablet, $susy) {
         background-color: $white;
         opacity: 1;
         max-width: 240px;
      }
      .card-img{
         @include span(6 of 12);
         position: relative;
         img {
            width: 100%;
            display: block;
            min-height: 135px;
         }
         @include susy-breakpoint($tablet, $susy) {
            @include span(12);
            img {
               min-height: 160px;
            }
         }
         @include susy-breakpoint(1024px, $susy) {
            img {
               min-height: 200px;
            }
         }
         @include susy-breakpoint($desktop, $susy) {
            @include span(12);
            img {
               min-height: 212px;
            }
         }
      }
      .card-description {
         @include span(6 of 12);
         color: $white;
         @include fontSize(10px);
         p {
            padding: 1em 1em 0;
         }
         @include susy-breakpoint($mobile, $susy) {
            @include fontSize(12px);
            p {
               padding: 1em 1.5em 0;
            }
         }
         @include susy-breakpoint($tablet, $susy) {
            @include span(12);
            p {
               padding: 1em 1em 0;
            }
         }
         @include susy-breakpoint(1024px, $susy) {
            p {
               padding: 2em 1.5em 0;
            }
         }
         @include susy-breakpoint($desktop, $susy) {
            p {
               padding: 3em 2em 0;
            }
         }
      }
      @include susy-breakpoint($tablet, $susy) {
         &:hover .card-description {
            //margin-top: -141px;
            opacity: 0.85;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            cursor: pointer;
         }
         /*&:hover .card-mask {
            bottom: 0;
            opacity: 0.85;
         }*/
      }
   }
   .card-description {
      height: 100%;
      background-color: #ef9738;
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -ms-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      @include susy-breakpoint($tablet, $susy) {
         opacity: 0;
         position: absolute;
      }
   }
}

.seguridad-beneficios {
   @include span(12);
   position: relative;
   background-color: $white;
   @include susy-breakpoint($tablet, $susy) {
      .owl-carousel .owl-controls .owl-buttons {
         left: 5px;
         right: 5px;
      }
   }
   .seguridad{
      @include span(12 of 12);
      height: 59vw;
      background-image: url('/images/seguridad_foto_mobile.png');
      @include susy-breakpoint($mobile, $susy) {
         height: 40vw;
      }
      @include susy-breakpoint($tablet, $susy) {
         @include span(6 of 12);
         background-image: url('/images/seguridad_foto.png');
         height: 25vw;
      }
      @include susy-breakpoint($desktop, $susy) {
         height: 328px;
      }
      background-size: cover;
      background-position: center;
      position: relative;
      overflow: hidden;
   }
   .beneficios{
      @include span(12 of 12);
      height: 59vw;
      background-image: url('/images/beneficios_foto_mobile.png');
      @include susy-breakpoint($mobile, $susy) {
         height: 40vw;
      }
      @include susy-breakpoint($tablet, $susy) {
         @include span(6 of 12);
         background-image: url('/images/beneficios_foto.png');
         height: 25vw;
      }
       @include susy-breakpoint($desktop, $susy) {
         height: 328px;
      }
      background-size: cover;
      background-position: center;
      position: relative;
      overflow: hidden;
   }
   h5{
      @include pad(3,0);
      @include span(9);
      color: $white;
      @include fontSize(12px);
      text-align: left;
   }
   h2{
      color: $white;
      @include fontSize(16.5px);
      text-transform: none;
      display: inline-block;
      text-align: left;
   }
   .title{
      @include span(12);
      height: 43px;
      &__icon{
         @include span(3 of 12);
      }
      &__h2{
         @include span(9 of 12);
         @include susy-breakpoint($mobile, $susy) {
            @include span(8 of 12);
         }
         @include susy-breakpoint($desktop, $susy) {
            @include span(6 of 12);
         }
      }
   }
   .security-icon{
      width: 32px;
      height: 43px;
   }
   .benefits-icon{
      width: 40px;
      height: 43px;
   }
   p{
      color: $white;
      @include fontSize(11px);
      @include susy-breakpoint($desktop, $susy) {
         @include fontSize(12.5px);
      }
   }
   .white-line {
      @include span(12);
      height: 1px;
      border-top: 1px solid $white;
      margin: 0.5em 0;
   }
   .securityCarousel{
      .absolute-center{
         width: 250px;
         height: 175px;
         @include susy-breakpoint($mobile, $susy) {
            width: 325px;
         }
         @include susy-breakpoint($tablet, $susy) {
            width: 270px;
         }
         @include susy-breakpoint($desktop, $susy) {
            width: 405px;
         }
      }
      .owl-pagination{
         bottom: 0;
         @include susy-breakpoint($mobile, $susy) {
            bottom: 1em;
         }
         @include susy-breakpoint($tablet, $susy) {
            bottom: 0;
         }
         @include susy-breakpoint($desktop, $susy) {
            bottom: 2em;
         }
      }
   }
}

.triangle {
   position: absolute;
   left: 0;
   right: 0;
   bottom: 0;
   display: -webkit-flex;
   display: flex;
   &:before,&:after {
      content: '';
      display: block;
      background-color: #fff;
      width: 48%;
   }
   &__form {
      width: 0;
      height: 0;
      border-top: 4vw solid transparent;
      border-left: 5vw solid #fff;
      border-right: 5vw solid #fff;
      display: block;
      margin: 0 auto;
   }
}

@include susy-breakpoint($desktop, $susy) {
   .triangle {
      &__form {
         border-top: 1.5vw solid transparent;
         border-left: 2.25vw solid #fff;
         border-right: 2.25vw solid #fff;
      }
   }
}
@include susy-breakpoint($tablet, $susy) {
   .triangle {
      &__form {
         border-top: 1.5vw solid transparent;
         border-left: 2.25vw solid #fff;
         border-right: 2.25vw solid #fff;
      }
   }
}
@include susy-breakpoint($mobile, $susy) {
   .triangle {
      &__form {
         border-top: 1.5vw solid transparent;
         border-left: 2.25vw solid #fff;
         border-right: 2.25vw solid #fff;
      }
   }
}
