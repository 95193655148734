ul, li {
   margin: 0;
   padding: 0;
   border: 0;
   font: inherit;
   font-size: 100%;
   vertical-align: baseline;
   font-family: 'Montserrat-Regular';
}

ol, ul {
   list-style: none;
}

a {
   margin: 0;
   padding: 0;
   text-decoration: none;
   color: $white;
   &:hover {
      cursor: pointer;
   }
}

p, span {
   margin: 0;
}

h1, h2, h3, h4, h5, h6 {
   margin: 0;
   text-transform: uppercase;
}

.cursive {
   font-family: 'LeckerliOne-Regular';
   text-transform: none;
   /*text-align: center;*/
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
}

.sup {
  font-size: 0.6rem;
  position: relative;
  bottom: 3px;
}

.bold {
   font-family: 'Montserrat-Bold';
}

button {
   border: none;
   padding: 0;
   margin: 0;
   font-family: 'Montserrat-Regular';
   &:hover {
      cursor: pointer;
   }
}

fieldset {
   border: none;
   padding: 0;
   margin: 0;
}

.blue-text {
   color: $navy-blue;
}

*:focus {
   outline: none;
}

.hidden{
	display: none;
}
.link-login {
   color: $navy-blue;
   text-decoration: underline;
}
.num-cel {
   text-decoration: underline;;
   font-family: 'Montserrat-Bold';
}
