.seguridad {
   @include span(12);
   background-color: $white;
   .security-header-desktop {
      @include span(12);
      position: relative;
      .security-bg {
         @include span(12);
         position: relative;
         background-repeat: no-repeat;
         background-position: center;
         background-size: cover;
         background-image: url('/images/seguridad_bg_mobile.png');
      }
      .security-inner {
         @include span(12);
         @include absolute;
         .text-block {
            @include span(12);
            position: relative;
            &__inner {
               position: absolute;
               h1 {
                  text-align: center;
                  color: $white;
               }
               h3 {
                  text-align: right;
                  position: absolute;
                  color: $white;
               }
            }
         }
      }
   }
   .security-content-desktop {
      .info {
         h2 {
            text-transform: none;
            font-family: 'Montserrat-Regular';
            text-align: center;
            color: $black;
         }
         p {
            color: $black;
            text-align: left;
         }
      }
   }
}

.seguridad {
   @include susy-breakpoint($phone, $susy) {
      .security-header-desktop {
         display: block;
         height: 70vh;
         .security-bg {
            height: 100%;
            background-image: url('/images/seguridad_bg_mobile.png');
         }
         .security-inner {
            height: 100%;
            .text-block {
               @include pad(2,4);
               @include span(6);
               height: 100%;
               &__inner {
                  top: 40%;
               }
               h1 {
                  font-size: 2.8rem;
               }
               h3 {
                  font-size: 1rem;
                  top: 45px;
                  left: 32%;
               }
            }
         }
      }
      .security-content-desktop {
         @include pad(1,1);
         @include span(10);
         margin: 2em 0;
         .info {
            @include pad(0,0);
            @include span(12);
            .info-inner {
               @include span(12);
               h2 {
                  font-size: 1.4rem;
                  margin-bottom: 2em;
               }
               p {
                  font-size: 1rem;
                  margin-bottom: 2em;
               }
            }
            .blue-line-down {
               @include span(12);
               margin: 3% 0;
               .line-down-img {
                  @include span(12);
               }
            }
         }
      }
   }
   @include susy-breakpoint($mobile, $susy) {
      .security-header-desktop {
         display: block;
         height: 70vh;
         .security-bg {
            height: 100%;
            background-image: url('/images/seguridad_bg.png');
         }
         .security-inner {
            height: 100%;
            .text-block {
               @include pad(4,4);
               @include span(4);
               height: 100%;
               &__inner {
                  top: 40%;
               }
               h1 {
                  font-size: 2.8rem;
               }
               h3 {
                  font-size: 1rem;
                  top: 45px;
                  left: 32%;
               }
            }
         }
      }
      .security-content-desktop {
         @include pad(1,1);
         @include span(10);
         margin: 5% 0;
         .info {
            @include pad(0.5,0.5);
            @include span(11);
            .info-inner {
               @include span(12);
               h2 {
                  font-size: 1.4rem;
                  margin-bottom: 2em;
               }
               p {
                  font-size: 1rem;
                  margin-bottom: 2em;
               }
            }
            .blue-line-down {
               @include span(12);
               margin: 3% 0;
               .line-down-img {
                  @include span(12);
               }
            }
         }
      }
   }
   @include susy-breakpoint($tablet, $susy) {
      .security-header-desktop {
         display: block;
         height: 70vh;
         .security-bg {
            height: 100%;
            background-image: url('/images/seguridad_bg.png');
         }
         .security-inner {
            height: 100%;
            .text-block {
               @include pad(3,3);
               @include span(6);
               height: 100%;
               &__inner {
                  top: 40%;
               }
               h1 {
                  font-size: 5rem;
               }
               h3 {
                  font-size: 1.2rem;
                  top: 85px;
                  left: 48%;
               }
            }
         }
      }
      .security-content-desktop {
         @include pad(1,1);
         @include span(10);
         margin: 2.5% 0;
         .info {
            @include pad(0.5,0.5);
            @include span(11);
            .info-inner {
               @include span(12);
               h2 {
                  font-size: 1.4rem;
                  margin-bottom: 2em;
               }
               p {
                  font-size: 1rem;
                  margin-bottom: 2em;
               }
            }
            .blue-line-down {
               @include span(12);
               margin: 3% 0;
               .line-down-img {
                  @include span(12);
               }
            }
         }
      }
   }
   @include susy-breakpoint($desktop, $susy) {
      .security-header-desktop {
         display: block;
         height: 70vh;
         .security-bg {
            height: 100%;
            background-image: url('/images/seguridad_bg.png');
         }
         .security-inner {
            height: 100%;
            .text-block {
               @include pad(4,4);
               @include span(4);
               height: 100%;
               &__inner {
                  top: 40%;
               }
               .faq-text-block {
                  .h1-faq {
                     font-size: 2.7rem;
                  }
                  .cursive {
                     font-size: 3.5rem;
                  }
               }
               h1 {
                  font-size: 5rem;
               }
               h3 {
                  font-size: 1.2rem;
                  top: 85px;
                  left: 55%;
               }
            }
         }
      }
      .security-content-desktop {
         @include pad(1,1);
         @include span(10);
         margin: 2.5% 0;
         .info {
            @include pad(0.5,0.5);
            @include span(11);
            .info-inner {
               @include span(12);
               h2 {
                  font-size: 1.4rem;
                  margin-bottom: 2em;
               }
               p {
                  font-size: 1rem;
                  margin-bottom: 2em;
               }
            }
            .blue-line-down {
               @include span(12);
               margin: 3% 0;
               .line-down-img {
                  @include span(12);
               }
            }
         }
      }
   }
}
