.mfp-bg{
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=6.5)"; /* IE 8 */
   filter: alpha(opacity=6.5); /* IE 5-7 */
   -moz-opacity: 0.65; /* Netscape */
   -khtml-opacity: 0.65; /* Safari 1.x */
   opacity: 0.65; /* Good browsers */
}
.modal-loading{
   width: 100%;
   .loading-gif{
      width: 35px;
   }
   p{
      color: #d0c7c7;
      @include fontSize(13px);
      margin: 1em 0;
   }
}
.modal{
   max-width: 300px;
   margin: auto;
   @include susy-breakpoint($mobile, $susy) {
      max-width: 460px;
   }
   @include susy-breakpoint($tablet, $susy) {
      max-width: 560px;
   }
   @include susy-breakpoint($desktop, $susy) {
      max-width: 640px;
   }
   .emoji{
      width: 45px;
      @include susy-breakpoint($tablet, $susy) {
         width: 60px;
      }
      @include susy-breakpoint($desktop, $susy) {
         width: 75px;
      }
   }
   .bg-blue{
      background-color: $navy-blue;
   }
   .bg-red{
      background-color: $red;
   }
   .h-mas{
      height: 80px;
      @include susy-breakpoint($mobile, $susy) {
         height: 55px;
      }
      @include susy-breakpoint($tablet, $susy) {
         height: 72px;
      }
   }
   &__heading{
      width: 100%;
      height: 55px;
      position: relative;
      padding-top: 1.2em;
      text-align: center;
      color: $white;
      border-radius: 15px;
      @include susy-breakpoint($tablet, $susy) {
         padding-top: 1.5em;
         height: 72px;
      }
      h2{
         @include fontSize(16px);
         font-weight: normal;
         padding: 0 0.5em;
         @include susy-breakpoint($mobile, $susy) {
            @include fontSize(18px);
         }
         @include susy-breakpoint($tablet, $susy) {
            @include fontSize(22px);
         }
      }
   }
   &__body{
      width: 100%;
      background-color: $white;
   }
   &__emoji{
      width: 100%;
      text-align: center;
      padding: 1em 0 0.5em;
   }
   &__text{
      width: 100%;
      margin: 0 auto;
      padding: 0.65em 0;
      width: 280px;
      text-align: center;
      color: #404040;
      @include fontSize(11.5px);
      @include susy-breakpoint($mobile, $susy) {
         padding: 0.5em 0;
         @include fontSize(13px);
         width: 415px;
      }
      @include susy-breakpoint($tablet, $susy) {
         @include fontSize(15px);
         width: 480px;
      }
      p{
         padding: 0.5em 0;
         @include susy-breakpoint($tablet, $susy) {
            padding: 0.8em 0;
         }
         a{
            color: #404040;
         }
         span{
            display: block;
         }
      }
   }
   &__leyenda{
      text-align: center;
      margin-top: -3em;
      padding-bottom: 2em;
      @include fontSize(10px);
      @include susy-breakpoint($mobile, $susy) {
         @include fontSize(11px);
      }
      @include susy-breakpoint($tablet, $susy) {
         @include fontSize(13px);
      }
   }
   &__logo {
     width: 200px;
     margin: 0 auto; 
   }
   .logo {
     width: 100%;
   }
   &__btn{
      width: 100%;
      margin: 1em 0 4em;
      display: inline-flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
   }
   .triangle{
      &__form{
         border-top: 4.5vw solid transparent;
         border-left: 4.5vw solid #fff;
         border-right: 4.5vw solid #fff;
         @include susy-breakpoint($mobile, $susy) {
            border-top: 3vw solid transparent;
            border-left: 3vw solid #fff;
            border-right: 3vw solid #fff;
         }
         @include susy-breakpoint($tablet, $susy) {
            border-top: 2.5vw solid transparent;
            border-left: 2.5vw solid #fff;
            border-right: 2.5vw solid #fff;
         }
         @include susy-breakpoint($desktop, $susy) {
            border-top: 1.5vw solid transparent;
            border-left: 1.5vw solid #fff;
            border-right: 1.5vw solid #fff;
         }
      }
   }
   .orange-btn{
      @include fontSize(13px);
      font-weight: lighter;
      padding: 0.5em 3em;
      @include susy-breakpoint($tablet, $susy) {
         padding: 0.8em 3em;
         @include fontSize(15px);
      }
   }
}
.popUp {
   background-color: $white;
   max-width: 300px;
   margin: auto;
   padding: 1em 2.5em 2em;
   position: relative;
   &__head {
      @include span(12);
      padding-bottom: 1em;
      margin-bottom: 2em;
      border-bottom: solid 3px $black;
   }
   &__title {
      @include span(12);
      padding: 1em 0;
      text-transform: none;
      @include fontSize(22px);
   }
   &__logo {
      @include span(12);
      background-color: $black;
   }
   &__img {
      width: 80%;
      display: block;
      padding: 1em 0;
      margin: auto;
   }
   @include susy-breakpoint($mobile, $susy) {
      max-width: 460px;
   }
   @include susy-breakpoint($tablet, $susy) {
      max-width: 560px;
      &__title {
         @include span(7);
      }
      &__logo {
         @include span(5);
      }
   }
   @include susy-breakpoint($desktop, $susy) {
      max-width: 700px;
   }
   &__phrase {
      margin: 0 0 1em;
   }
   &__link {
      text-decoration: underline;
   }
}
