$white:        #ffffff;
$black:        #000000;
$dark:         #282828;
$dark-black:   #1a1a1b;
$pink:         #f67283;
$green:        #00a57d;
$navy-blue:    #005488;
$gray:         #828282;
$light-gray:   #b7b7b7;
$light-gray-2: #b8b8b8;
$purple:       #68375b;
$red:          #c51111;
$yellow:       #fec220;
$yellow-brown: #a87d2c;
$blue:         #3f9cd4;
$light-blue:   #7bc8f6;
$sky-blue:     #44b4f6;
$navy-blue:    #2790ce;
$dark-blue:    #21506c;
$orange:       #f79020;

.bck-light-blue {
   background-color: $light-blue;
}

.bck-sky-blue {
   background-color: $sky-blue;
}

.bck-navy-blue {
   background-color: $navy-blue;
}

.bck-orange {
	background-color: $orange;
}

.black {
   color: $black;
}
.sky-blue {
   color: $sky-blue;
}
.dark-blue {
   color: $dark-blue;
}
.navy-blue {
   color: $navy-blue;
}
