.header{
	width: 100%;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 10;
   background-color: $dark;
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=95)"; // IE 8
   filter: alpha(opacity=95); // IE 5-7
   -moz-opacity: 0.949; // Netscape
   -khtml-opacity: 0.949; // Safari 1.x
   opacity: 0.949; // Good browsers
   &__container {
		@include pad(0.5,0.5);
      @include span(11);
      position: relative;
      @include susy-breakpoint($mobile, $susy) {
         @include pad(1,1);
         @include span(10);
      }
      @include susy-breakpoint(1024px, $susy) {
         @include pad(0.5,0.5);
         @include span(11);
      }
	}
	&__logo {
		display: block;
		width: 120px;
		padding: 1.15em 0;
      @include susy-breakpoint($mobile, $susy) {
         width: 150px;
         padding: 1em 0;
      }
      @include susy-breakpoint(1024px, $susy) {
         display: inline-block;
         width: 175px;
      }
      @include susy-breakpoint($desktop, $susy) {
         width: 190px;
         padding: 0.85em 0;
      }
		& img{
         display: block;
         width: 100%;
		}
	}
   &__btn{
      position: absolute;
      top: 13%;
      left: 50%;
      width: 85px;
      text-align: center;
      @include fontSize(12px);
      @include susy-breakpoint($mobile, $susy) {
         top: 12%;
         left: 65%;
      }
      @include susy-breakpoint($tablet, $susy) {
         left: 70%;
      }
      @include susy-breakpoint(1024px, $susy) {
         width: 160px;
         @include fontSize(13px);
         left: 78%;
         top: 20%;
      }
       @include susy-breakpoint($desktop, $susy) {
         left: 81%;
      }
   }
   .orange-btn{
      padding: 0.5em 0.8em;
      @include susy-breakpoint(1024px, $susy) {
         padding: 0.8em;
      }
   }
   &__menu-mobile{
      position: absolute;
      width: 30px;
      height: 30px;
      display: block;
      margin: 0 0.5em;
      right: 2%;
      top: 11px;
      @include susy-breakpoint($mobile, $susy) {
         right: 8%;
      }
      @include susy-breakpoint(1024px, $susy) {
         display: none;
      }
      & .burguer {
         @include fullLine(2px,$white);
         top: 10px;
         &:after, &:before {
            content: '';
            @include fullLine(2px,$white);
         }
         &:before {
            top: 8px;
         }
         &:after {
            top: 16px;
         }
      }
   }
   &__nav {
		position: fixed;
		/*height: 100vh;*/
		width: 200px;
		top: 62px;
		right: -200px;
		@include transition(right 0.25s);
		&.active{
			right: 0;
		}
	}
}

.menu {
	width: 200px;
	position: relative;
	text-align: center;
   @include susy-breakpoint(1024px, $susy) {
      width: auto;
      display: none;
   }
   &__item{
      background-color: rgba($dark,0.95);
      margin-bottom: 0.15em;
      @include susy-breakpoint(1024px, $susy) {
         float: left;
         margin: 0 0.6em;
         background-color: transparent;
      }
      @include susy-breakpoint($desktop, $susy) {
         margin: 0 1.2em;
      }
	}
	&__link{
		color: $white;
		padding: 1em 0;
		display: block;
      @include fontSize(12px);
      @include susy-breakpoint(1024px, $susy) {
         @include fontSize(13px);
      }
      @include susy-breakpoint($desktop, $susy) {
         @include fontSize(14px);
      }
	}
   @include susy-breakpoint($desktop, $susy){
      /*Effect: bottom border enlarge*/
      a {
         padding: 0 0 10px;
         position: relative;
      }
      a::after {
         position: absolute;
         top: 100%;
         left: 0;
         width: 100%;
         height: 1px;
         background: #fff;
         content: '';
         opacity: 0;
         -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
         -moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
         transition: height 0.3s, opacity 0.3s, transform 0.3s;
         @include transform(translateY(-10px));
      }
      :hover::after,
      a:focus::after {
         height: 2px;
         opacity: 1;
         @include transform(translateY(0px));
      }
      /*End Effect*/
   }
}

.menu-desktop{
   display: none;
   @include susy-breakpoint(1024px, $susy) {
      display: inline-block;
      position: absolute;
      top: 12px;
      right: 23%;
   }
   @include susy-breakpoint($desktop, $susy) {
      top: 26px;
      right: 20%;
   }
}
