.terminos-condiciones {
	@include span(12);
	margin-top: 100px;
	margin-bottom: 80px;
	h1{
		text-align: left;
	}
	.principal {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h1 {
			padding: 1em 0;
			text-transform: none;
			font-size: 1.6rem;
		}
		p {
			padding: 1.5em 0;
		}
	}
	.terminos-generales {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h1 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		p {
			padding: 1.5em 0;
		}
		ul {
   			@include pad(0,0);
			@include span(12);
   			list-style: initial;
   			li {
   				padding: 1em 0;
   			}
		}
	}
	.personalidad {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h1 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		ul {
			@include pad(0,0);
			@include span(12);
   			list-style: initial;
   			li {
   				padding: 1em 0;
   			}
		}
	}
	.legalidad {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h1 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		ul {
   			@include pad(0,0);
			@include span(12);
   			list-style: initial;
   			li {
   				padding: 1em 0;
   			}
		}
	}
	.informacion-usuario {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h1 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		ul {
   			@include pad(0,0);
			@include span(12);
   			list-style: initial;
   			li {
   				padding: 1em 0;
   			}
		}
	}
	.propiedad-intelectual {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h1 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		ul {
   			@include pad(0,0);
			@include span(12);
   			list-style: initial;
   			li {
   				padding: 1em 0;
   			}
		}
	}
	.informacion-servidores {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h1 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		ul {
   			@include pad(0,0);
			@include span(12);
   			list-style: initial;
   			li {
   				padding: 1em 0;
   			}
		}
	}
	.sub-principal {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h1 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		ul {
   			@include pad(0,0);
			@include span(12);
   			list-style: initial;
   			li {
   				padding: 1em 0;
   			}
		}
	}
	.cargos-datos {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h1 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		ul {
   			@include pad(0,0);
			@include span(12);
   			list-style: initial;
   			li {
   				padding: 1em 0;
   			}
		}
	}
	.validez-legalidad {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h1 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		ul {
   			@include pad(0,0);
			@include span(12);
   			list-style: initial;
   			li {
   				padding: 1em 0;
   			}
		}
	}
	.contrasenas {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h1 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		ul {
   			@include pad(0,0);
			@include span(12);
   			list-style: initial;
   			li {
   				padding: 1em 0;
   				display: block;
   			}
   			ul {
   				@include pad(1,1);
				@include span(10);
   				list-style: initial;
   				li {
   					padding: 1em 0;
   				}
   			}
		}
	}
	.horarios-servicio {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h1 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		p {
			padding: 1.5em 0;
		}
	}
	.leyes {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h1 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		p {
			padding: 1.5em 0;
		}
	}
}
