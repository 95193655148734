.simu-tarjeta-de-credito{
	@include span(12);
	margin-bottom: 2em;
	&__content{
		@include span(12);
		h2{
			@include fontSize(15px);
			text-transform: none;
			padding: 0.5em 0;
		}
		p{
			@include fontSize(12px);
		}
	}
	&__row{
		@include span(12);
		margin: 2em 0 0;
	}
	&__item{
		@include span(12);
		margin: 1em 0;
		&__content{
			@include span(12);
			margin: 2em 0;
			&:nth-child(10n+1){
				margin: 0 0 2em;
			}
			.label{
				margin: 1em 0;
			}
			input{
				top: 15px;
			}
			.trigger-scroll{
				margin-top: 19px;
			}
		}
		.simu-tag{
			height: 32px;
		}
	}
	&__leyenda{
	   @include span(12);
	   color: $light-gray;
	   @include fontSize(11px);
	   margin: 0.5em 0;
	}
	&__buttons{
		@include span(12);
		margin: 2em 0;
		&__content{
			@include span(12);
			.btn{
	            margin: 1em auto;
	            width: 200px;
	            .orange-btn{
	               width: 100%;
	               @include fontSize(13px);
	            }
	        }
		}
	}
	table {
        @include span(12);
        color: $black;
        border-radius: 5px;
        background-color: $sky-blue;
        th {
           background-color: transparent;
           text-transform: uppercase;
          	@include fontSize(13px);
           color: $white;
           text-align: center;
           height: 50px;
       	}
        td {
           background-color: $white;
           @include fontSize(13px);
           color: $light-gray;
           text-align: center;
           height: 40px;
        }
        label {
           @include fontSize(14px);
           color: $black;
        }
        strong {
           label {
              @include fontSize(14px);
              color: $light-gray;
           }
        }
    }
	@include susy-breakpoint($mobile, $susy){
		&__item{
			@include span(12);
			&__content{
				@include span(5.5 of 12);
				&:nth-child(10n+1),
				&:nth-child(10n+3),
				&:nth-child(10n+5){
					@include pad(0,0.5);
				}
				&:nth-child(10n+2),
				&:nth-child(10n+4),
				&:nth-child(10n+6){
					@include pad(0.5,0);
				}
				&:nth-child(10n+7){
					@include pad(2.5,2.5);
					@include span(7);
				}
				&:nth-child(10n+8){
					@include span(12);
				}
				&:nth-child(10n+9),
				&:nth-child(10n+10){
					@include pad(2.5,2.5);
					@include span(7);
				}
				&:nth-child(10n+2){
					margin: 0 0 6em;
				}
				&:nth-child(10n+3),
				&:nth-child(10n+4){
					margin: 0 0 2em;
				}
			}
		}
	}
	@include susy-breakpoint($tablet, $susy){
	}
	@include susy-breakpoint($desktop, $susy){
		&__item{
			@include span(12);
			&__content{
				&:nth-child(10n+1),
				&:nth-child(10n+2),
				&:nth-child(10n+3){
					@include span(2.5 of 12);
					@include pad(0,0.5);
				}
				&:nth-child(10n+4){
					@include pad(0,0);
					@include span(2.75 of 12);
				}
				&:nth-child(10n+3){
					@include span(2.75 of 12);
				}
				&:nth-child(10n+1),
				&:nth-child(10n+2){
					margin: 0 0 2em;
				}
				&:nth-child(10n+2),
				&:nth-child(10n+3),
				&:nth-child(10n+4){
					margin: 0 0 6em;
				}
				&:nth-child(10n+5),
				&:nth-child(10n+6),
				&:nth-child(10n+7){
					@include span(3.5 of 12);
				}
				&:nth-child(10n+5){
					@include pad(0,0.5);
				}
				&:nth-child(10n+6){
					@include pad(0.25,0.25);
				}
				&:nth-child(10n+7){
					@include pad(0.5,0);
				}
				&:nth-child(10n+9),
				&:nth-child(10n+10){
					margin: 5em 0 0;
					@include pad(0,0.5);
					@include span(5.5 of 12);
				}
				&:nth-child(10n+10){
					@include pad(0.5,0);
				}
			}
		}
	}
}
