.form-verificar{
	@include pad(0.5,0.5);
	@include span(11);
	margin-bottom: 2em;
	.disabled-btn {
      	display: none;
   	}
   	.enabled-btn {
      	display: block;
   	}
	&__content{
		@include span(12);
		border-top: solid 1px $light-gray;
		padding: 1em 0;
		h2{
			@include fontSize(18px);
			text-transform: none;
			padding: 0.5em 0;
		}
		p{
			@include fontSize(12px);
		}
	}
	&__row{
		@include span(12);
		margin: 2em 0 0;
	}
	&__item{
		@include span(12);
		margin: 1em 0;
		&__content{
			@include pad(1,1);
			@include span(10);
			.label{
				margin: 1em 0;
			}
			input{
				top: 15px;
			}
		}
	}
	&__buttons{
		@include span(12);
		margin-bottom: 2em;
		&__content{
			@include span(12);
			.btn{
	            margin: 1em auto;
	            width: 200px;
	            .blue-white-btn{
	               width: 100%;
	               @include fontSize(13px);
	            }
	        }
	        #resend_code_link{
	        	text-decoration: underline;
	        	color: $sky-blue;
	        	@include fontSize(13px);
	        }
		}
	}
	@include susy-breakpoint($mobile, $susy){
		@include pad(1,1);
		@include span(10);
		&__item{
			&__content{
				@include pad(3,3);
				@include span(6);
			}
		}
	}
	@include susy-breakpoint($tablet, $susy){
		@include pad(0.5,0.5);
		@include span(11);
		&__item{
			&__content{
				@include pad(4.25,4.25);
				@include span(3.5);
			}
		}
	}
	@include susy-breakpoint($desktop, $susy){
		@include pad(1,1);
		@include span(10);
		&__item{
			&__content{
				@include pad(4.5,4.5);
				@include span(3);
			}
		}
	}
}