.form-solicitud{
	@include pad(0.5,0.5);
	@include span(11);
	margin-bottom: 2em;
	.hidden {
      display: none;
   	}
	&__content{
		@include span(12);
		border-top: solid 1px $light-gray;
		padding: 1em 0;
		h2{
			@include fontSize(18px);
			text-transform: none;
			padding: 0.5em 0;
		}
		p{
			@include fontSize(12px);
		}
	}
	&__row{
		@include span(12);
		margin: 2em 0 0;
	}
	&__item{
		@include span(12);
		margin: 1em 0;
		&__content{
			@include span(12);
			margin: 2em 0;
			.label{
				margin: 1em 0;
			}
			input{
				top: 15px;
			}
			&:nth-child(10n+1){
				@include pad(0,0);
				@include span(12 of 12);
				margin: 0 0 2em;
				.inputs-dom{
					@include span(12);
					.my-input{
						&:nth-child(10n+1){
							@include span(6);
						}
						&:nth-child(10n+2){
							@include span(3);
						}
						&:nth-child(10n+3){
							@include span(3);
						}
					}
				}
			}
			&:nth-child(10n+2){
				margin: 0 0 2em;
			}
			.trigger-scroll{
				margin-top: 16px;
			}
		}
	}
	&__buttons{
		@include span(12);
		margin-bottom: 2em;
		&__content{
			@include span(12);
			.btn{
	            margin: 1em auto;
	            width: 200px;
	            .blue-white-btn{
	               width: 100%;
	               @include fontSize(13px);
	            }
	        }
		}
	}
	@include susy-breakpoint($mobile, $susy){
		@include pad(1,1);
		@include span(10);
		#notificacion-solicitud-1{
			text-align: center;
		}
		&__item{
			@include span(12);
			margin: 1em 0;
			&__content{
				&:nth-child(10n+1){
					@include pad(0,0);
					@include span(12 of 12);
					margin: 0 0 0em;
					.inputs-dom{
						@include span(12);
						.my-input{
							&:nth-child(10n+1){
								@include span(6);
							}
							&:nth-child(10n+2){
								@include span(3);
							}
							&:nth-child(10n+3){
								@include span(3);
							}
						}
					}
				}
				&:nth-child(10n+2),
				&:nth-child(10n+4),
				&:nth-child(10n+6){
					@include pad(0,0.5);
					@include span(5.5 of 12);
				}
				&:nth-child(10n+4),
				&:nth-child(10n+5),
				&:nth-child(10n+6){
					margin: 2em 0 3em;
				}
				&:nth-child(10n+5){
					@include pad(0.5,0);
					@include span(5.5 of 12);
				}
				&:nth-child(10n+3){
					@include pad(0.5,0);
					@include span(5.5 of 12);
					margin: 2em 0 4em;
				}
				&:nth-child(10n+2){
					margin: 2em 0;
				}
				&:nth-child(10n+6){
					margin: 0 0 2em;
				}
			}
		}
	}
	@include susy-breakpoint($tablet, $susy){
		@include pad(0.5,0.5);
		@include span(11);
		#notificacion-solicitud-1{
			text-align: right;
		}
		&__item{
			@include span(12);
			margin: 1em 0;
			&__content{
				&:nth-child(10n+1){
					@include pad(0,0);
					@include span(9.5 of 12);
					margin: 0 0 2em;
					.inputs-dom{
						@include span(12);
						.my-input{
							&:nth-child(10n+1){
								@include span(6);
							}
							&:nth-child(10n+2){
								@include span(3);
							}
							&:nth-child(10n+3){
								@include span(3);
							}
						}
					}
				}
				&:nth-child(10n+2){
					@include pad(0.5,0);
					@include span(2 of 12);
					margin: 0;
				}
				&:nth-child(10n+3),
				&:nth-child(10n+5){
					@include pad(0,0.5);
					@include span(5.5 of 12);
				}
				&:nth-child(10n+4),
				&:nth-child(10n+6){
					@include pad(0.5,0);
					@include span(5.5 of 12);
				}
				&:nth-child(10n+4),
				&:nth-child(10n+3){
					margin: 0;
				}
				&:nth-child(10n+6){
					margin: 2em 0;
				}
			}
		}
		&__buttons{
			&__content{
				@include span(6 last of 12);
				.btn{
		            margin: 0;
		            float: right;
		        }
			}
		}
	}
	@include susy-breakpoint($desktop, $susy){
		@include pad(1,1);
		@include span(10);
		&__item{
			@include span(12);
			margin: 1em 0;
			&__content{
				&:nth-child(10n+1){
					@include pad(0,0);
					@include span(6 of 12);
					margin: 0 0 2em;
					.inputs-dom{
						@include span(12);
						.my-input{
							&:nth-child(10n+1){
								@include span(6);
							}
							&:nth-child(10n+2){
								@include span(3);
							}
							&:nth-child(10n+3){
								@include span(3);
							}
						}
					}
				}
				&:nth-child(10n+2){
					@include pad(0.25,0);
					@include span(1.75 of 12);
					margin: 0 0 4em;
				}
				&:nth-child(10n+3){
					@include pad(0.25,0);
					@include span(3.75 of 12);
					margin: 0 0 6em;
				}
				&:nth-child(10n+4){
					@include span(3.75 of 12);
					@include pad(0,0.25);
				}
				&:nth-child(10n+5){
					@include span(3.75 of 12);
					@include pad(0.125,0.125);
					margin: 0;
				}
				&:nth-child(10n+6){
					@include span(3.75 of 12);
					@include pad(0.25,0);
					margin: 0 0 2em;
				}
			}
		}
	}
}
