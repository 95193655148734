.faqs{
   @include span(12);
   background-color: $white;
   &__header {
      @include span(12);
      position: relative;
      height: 70vh;
      .faqs-bg {
         @include span(12);
         position: relative;
         background-repeat: no-repeat;
         background-position: center;
         background-size: cover;
         background-image: url('/images/faq_bg_mobile.png');
         height: 100%;
      }
      .faqs-inner {
         @include span(12);
         margin: auto;
         @include absolute;
         height: 20%;
         width: 250px;
         h1 {
            text-align: center;
            color: $white;
            font-size: 2rem;
         }
         .cursive {
            font-size: 1.45em;
         }
      }
   }
   &__content{
      @include pad(1,1);
   	@include span(10);
      margin: 1em 0 6em;
   }
   &__row{
		@include span(12);
	}
	&__item{
		@include span(12);
		margin: 1em 0;
		&__content{
			@include span(12);
         margin: 1em 0 3em;
      }
   }
   .my-dropdown{
      @include span(12);
      position: relative;
      &__content{
         @include span(12);
         height: 100%;
         background: transparent url(/images/icons/down_arrow.png) 98% center no-repeat;
         height: 55px;
         border: solid 1px $sky-blue;
         border-top: none;
         overflow: hidden;
         .trigger-scroll{
            @include pad(1,1);
            @include span(10);
            @include transition(all 0.25s ease);
            @include fontSize(13px);
            height: 100%;
         }
      }
      a{
        color: $black;
      }
      .dropdownvisible-scroll{
        @include span(12);
        margin-top: 10px;
        border: solid 1px $light-gray;
        color: $black;
        background-color: $white;
        position: relative;
        z-index: 2;
        @include transition(all 0.5s ease);
        li{
           @include pad(1,1);
           @include span(10);
           padding-top: 1.2em;
           padding-bottom: 1.2em;
          @include fontSize(13px);
          color: $black;
          border-radius: 5px;
          @include transition(all 0.5s ease);
        }
        li:before {
           content: url('/images/icons/yellow_check.png');
           padding: 0 0.5em 0 0;

        }
      }
      .dropdownvisible-scroll::before,
      .dropdownvisible-scroll:after{
         content: '';
         display: block;
         position: absolute;
         left: 92%;
         width: 0;
         height: 0;
         border-style: solid;
      }
      /* this border color controlls the color of the triangle (what looks like the fill of the triangle) */
      .dropdownvisible-scroll:after {
         top: 0;
         border-color: transparent $white $white $white;
         border-width: 10px;
      }
      /* this border color controlls the outside, thin border */
      .dropdownvisible-scroll:before {
         top: -1px;
         border-color: $white $light-gray $light-gray $light-gray;
         border-width: 10px;
      }
      .dropdownhidden-scroll{
        display: none;
      }
   }
   .closed{
      .items-drop-down {
         display: none;
      }
   }
   @include susy-breakpoint($mobile, $susy) {
      &__header {
         height: 80vh;
         .faqs-bg {
            background-image: url('/images/faq_bg.png');
         }
         .faqs-inner {
            height: 20%;
            width: 100%;
         }
      }
      &__item{
         &__content{
            @include span(12);
            height: 50px;
            margin: 1em 0 3em;
         }
      }
      .my-dropdown{
         &__content{
            height: 50px;
         }
      }
   }
   @include susy-breakpoint($tablet, $susy) {
      &__header {
         height: 60vh;
         .faqs-bg {
            background-image: url('/images/faq_bg.png');
         }
         .faqs-inner {
            height: 20%;
            width: 250px;
            h1 {
               font-size: 2.2rem;
            }
            .cursive {
               font-size: 1.5em;
            }
         }
      }
      &__item{
         &__content{
            height: 50px;
            margin: 1em 0 3em;
            &:nth-child(10n+1),
            &:nth-child(10n+3),
            &:nth-child(10n+5),
            &:nth-child(10n+7),
            &:nth-child(10n+9),
            &:nth-child(10n+11),
            &:nth-child(10n+13),
            &:nth-child(10n+15){
               @include pad(0,1);
               @include span(5.5 of 12);
            }
            &:nth-child(10n+2),
            &:nth-child(10n+4),
            &:nth-child(10n+6),
            &:nth-child(10n+8),
            &:nth-child(10n+10),
            &:nth-child(10n+12),
            &:nth-child(10n+14){
               @include pad(0,0);
               @include span(5.5 of 12);
            }
         }
      }
      .my-dropdown{
         &__content{
            .trigger-scroll{
               @include fontSize(12.5px);
            }
         }
         .dropdownvisible-scroll{
            li{
               @include fontSize(12.5px);
            }
         }
      }
   }
   @include susy-breakpoint($desktop, $susy) {
      &__header {
         height: 60vh;
         .faqs-bg {
            background-image: url('/images/faq_bg.png');
         }
         .faqs-inner {
            height: 25%;
            width: 280px;
            h1 {
               font-size: 2.5rem;
            }
            .cursive {
               font-size: 1.55em;
            }
         }
      }
      &__item{
         &__content{
            &:nth-child(10n+1),
            &:nth-child(10n+4),
            &:nth-child(10n+7),
            &:nth-child(10n+10){
               @include pad(0,0.75);
               @include span(3.5 of 12);
            }
            &:nth-child(10n+2),
            &:nth-child(10n+5),
            &:nth-child(10n+8),
            &:nth-child(10n+11),
            &:nth-child(10n+14){
               @include pad(0,0);
               @include span(3.5 of 12);
            }
            &:nth-child(10n+6),
            &:nth-child(10n+9),
            &:nth-child(10n+12),
            &:nth-child(10n+15){
               @include pad(0.75,0);
               @include span(3.5 of 12);
            }
            &:nth-child(10n+3){
               @include pad(0.75,0);
               @include span(3.5 of 12);
            }
            &:nth-child(10n+13){
               @include pad(0,0.75);
               @include span(3.5 of 12);
            }
         }
      }
      .my-dropdown{
         &__content{
            .trigger-scroll{
               @include fontSize(13px);
            }
         }
         .dropdownvisible-scroll{
            li{
               @include fontSize(12.5px);
               @include pad(0.5,0.5);
               @include span(11);
            }
         }
      }
   }
}
