.footer{
   @include span(12);
   position: relative;
   background-color: $dark;
   color: $white;
   text-align: center;
   padding: 1em 0;
   h5{
      @include fontSize(13px);
      text-transform: none;
   }
   &__social{
      @include span(12);
      margin: 1em 0;
      li{
         display: inline-block;
         margin: 0 0.5em;
         .img-face{
            width: 32px;
            height: 28px;
            @include susy-breakpoint($desktop, $susy) {
               &:hover{
                  content: url('/images/icons/facebook_hover.png');
               }
            }
         }
         .img-tw{
            width: 30px;
            height: 30px;
            @include susy-breakpoint($desktop, $susy) {
               &:hover{
                  content: url('/images/icons/twitter_hover.png');
               }
            }
         }
      }
   }
   p{
      @include fontSize(11px);
      margin: 0.5em 0;
   }
   .icon-inline{
      width: 1em;
      height: 1em;
      display: inline-block;
      position: relative;
      top: 0.2em;
   }
   &__schema{
      @include pad(0.5,0.5);
      @include span(11);
      @include susy-breakpoint($mobile, $susy) {
         @include pad(1,1);
         @include span(10);
      }
   }
   &__legalContent {
      @include span(12);
      float: none;
      max-width: 768px;
      margin: 0 auto;
   }
   &__legal{
      @include pad(2,2);
      @include span(8);
      margin: 1em auto;
      @include susy-breakpoint($tablet, $susy) {
         @include pad(1,1);
         @include span(10);
      }
      li{
         @include fontSize(12px);
         &:nth-child(10n+1){
            @include pad(0,1);
            @include span(5);
            text-align: right;
            @include susy-breakpoint($mobile, $susy) {
               @include pad(0,0.5);
               @include span(5.5);
            }
            @include susy-breakpoint($tablet, $susy) {
               @include pad(0,1);
               @include span(5);
            }
            @include susy-breakpoint($desktop, $susy) {
               @include pad(0,0.5);
               @include span(5.5);
            }
         }
         &:nth-child(10n+2){
            @include pad(1,0);
            @include span(5);
            text-align: left;
            @include box-shadow(2px 0px 0px -1px #fff inset);
            @include susy-breakpoint($mobile, $susy) {
               @include pad(0.5,0);
               @include span(5.5);
            }
            @include susy-breakpoint($tablet, $susy) {
               @include pad(1,0);
               @include span(5);
            }
            @include susy-breakpoint($desktop, $susy) {
               @include pad(0.5,0);
               @include span(5.5);
            }
         }
         &:nth-child(10n+3){
            @include pad(0,0);
            @include span(12);
            margin: 1em auto;
            text-align: center;
         }
         @include susy-breakpoint($tablet, $susy) {
            &:nth-child(10n+1){
               @include pad(0,0);
               @include span(4);
               text-align: center;
            }
            &:nth-child(10n+2){
               @include pad(0,0);
               @include span(4);
               text-align: center;
            }
            &:nth-child(10n+3){
               @include pad(0,0);
               @include span(4);
               margin: 0 auto;
               @include box-shadow(2px 0px 0px -1px #fff inset);
               text-align: center;
            }
         }
         /*Effect: bottom border enlarge*/
         @include susy-breakpoint($desktop, $susy) {
            a {
               padding: 0 0 10px;
               position: relative;
            }
            a::after {
               position: absolute;
               top: 100%;
               left: 0;
               width: 100%;
               height: 1px;
               background: #fff;
               content: '';
               opacity: 0;
               -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
               -moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
               transition: height 0.3s, opacity 0.3s, transform 0.3s;
               @include transform(translateY(-10px));
            }
            :hover::after,
            a:focus::after {
               height: 2px;
               opacity: 1;
               @include transform(translateY(0px));
            }
         }
         /*End Effect*/
      }
   }
   &__certificate {
      @include span(12);
      margin: 1em 0 0.5em;
      span{
         width: 190px;
         display: block;
         margin: 0 auto;
         img {
            width: 100%;
         }
      }
   }
}
