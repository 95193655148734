.first-block-image{
   position: relative;
   width: 100%;
   height: 83%;
   background-size: cover;
   background-position: center;
   background-color: $white;
   background-image: url('/images/foto_mobile_transfiere.png');
   &__container{
      height: 100%;
      text-align: center;
   }
   h1{
      color: $white;
      @include fontSize(28px);
      width: 215px;
      margin: auto;
      @include susy-breakpoint($mobile, $susy) {
         width: 480px;
      }
      @include susy-breakpoint($tablet, $susy) {
         width: 215px;
      }
      @include susy-breakpoint($desktop, $susy) {
         width: 480px;
         @include fontSize(34px);
      }
   }
   p{
      color: $white;
      @include fontSize(14px);
      width: 265px;
      margin: auto;
      @include susy-breakpoint($mobile, $susy) {
         width: 480px;
      }
   }
   .cursive{
      @include fontSize(38px);
      @include susy-breakpoint($desktop, $susy) {
         @include fontSize(46px);
      }
   }
   @include susy-breakpoint($mobile, $susy) {
      height: 100%;
      background-image: url('/images/foto_desk_transfiere.png');
   }
   @include susy-breakpoint($tablet, $susy) {
      height: 80%;
      min-height: 480px;
   }
   @include susy-breakpoint($desktop, $susy) {
      min-height: 480px;
   }
   .absolute-center{
      height: 50%;
      @include susy-breakpoint($mobile, $susy) {
         height: 35%;
      }
      @include susy-breakpoint($tablet, $susy) {
         height: 28%;
      }
      @include susy-breakpoint($desktop, $susy) {
         height: 25%;
      }
   }
}

.center-container{
   position: relative;
}
.absolute-center{
   margin: auto;
   position: absolute;
   top: 0; left: 0; bottom: 0; right: 0;
}

.inner{
   @include pad(0.5,0.5);
   @include span(11);
}

.active-btn {
   background: $sky-blue;
   color: $white;
   border: solid 1px $sky-blue;
}
.inactive-btn {
   background: $light-gray;
   color: $white;
   border: solid 1px $light-gray;
}
.credit-btn{
   &:hover{
      cursor: not-allowed;
   }
}

.simu-options{
   @include span(12);
   position: relative;
   background-color: $white;
   margin: 0.5em 0;
   text-align: left;
   @include susy-breakpoint($mobile, $susy) {
      text-align: center;
      margin: 1.5em 0;
   }
   h2{
      color: $black;
      text-transform: none;
      @include fontSize(15px);
      @include susy-breakpoint($mobile, $susy) {
         @include fontSize(17px);
      }
   }
   p{
      color: $black;
      @include fontSize(12px);
      margin: 0.5em 0;
      @include susy-breakpoint($mobile, $susy) {
         @include fontSize(12.5px);
         margin: 1em 0;
      }
   }
   .buttons{
      @include span(12);
      padding: 0 0 1em;
      border-bottom: solid 1px $sky-blue;
      @include susy-breakpoint($mobile, $susy) {
         padding: 0 0 1em;
      }
      button{
         @include span(12);
         @include fontSize(12.5px)
         @include susy-breakpoint($tablet, $susy) {
            @include fontSize(14px);
         }
         @include susy-breakpoint($desktop, $susy) {
            @include fontSize(15px);
         }
      }
      .calcula-credito{
         @include span(12);
         margin: 1em 0;
         @include susy-breakpoint($mobile, $susy) {
            @include pad(3,3);
            @include span(6);
            margin: 0.5em 0 1em;
         }
         @include susy-breakpoint($desktop, $susy) {
            @include pad(0,0);
            @include span(4);
            margin: 2.18em 0 1em;
         }
      }
      .tipo-deuda{
         @include span(12);
         text-align: left;
         @include susy-breakpoint($desktop, $susy) {
            @include span(8);
            text-align: center;
         }
         h3{
            color: $sky-blue;
            @include fontSize(14px);
            text-transform: none;
            margin: 0 0 1em;
         }
         .btn-credito-personal{
            @include pad(0,0.25);
            @include span(5.75);
            @include susy-breakpoint($desktop, $susy) {
               @include pad(0.5,0);
               @include span(5.5);
            }
            .gray-btn{
               padding: 0.8em 1.2em;
               @include susy-breakpoint($mobile, $susy) {
                  padding: 0.8em;
               }
            }
         }
         .btn-tarjeta-credito{
            @include pad(0.25,0);
            @include span(5.75);
            @include susy-breakpoint($desktop, $susy) {
               @include pad(0.5,0);
               @include span(5.5);
            }
            .blue-white-btn{
               padding: 0.8em 1.2em;
               @include susy-breakpoint($mobile, $susy) {
                  padding: 0.8em;
               }
            }
         }
      }
   }
}

.simu {
   @include span(12);
   position: relative;
   background-color: $white;
}

.conoce-beneficios{
   position: relative;
   @include span(12);
   background-size: cover;
   background-position: center;
   background-color: $white;
   background-image: url('/images/foto_mobile_conoce_footer.png');
   border-top: solid 1px $sky-blue;
   text-align: center;
   padding-bottom: 3em;
   @include susy-breakpoint($mobile, $susy) {
      background-image: url('/images/foto_desktop_conoce_footer.png');
   }
   h2{
      color: $black;
      @include fontSize(15px);
      text-transform: none;
      margin: 1.5em 0 3em;
      @include susy-breakpoint($mobile, $susy) {
         @include fontSize(17px);
      }
   }
   .beneficio {
      @include span(12);
      margin-bottom: 2em;
      .left-line {
         @include span(0.5);
         @include susy-breakpoint($desktop, $susy) {
            @include span(2);
         }
         .line {
            width: 10px;
            height: 100px;
            border: solid 1px $sky-blue;
            border-right: none;
         }
      }
      .block {
         @include span(11);
         text-align: center;
         height: 100px;
         @include susy-breakpoint($desktop, $susy) {
            @include span(8);
         }
         h4 {
            @include fontSize(14px);
            color: $black;
            text-transform: none;
            font-family: 'Montserrat-Bold';
            margin: 0 0 0.5em;
         }
         p {
            @include fontSize(12px);
            color: $black; 
         }
      }
      .right-line {
         @include span(0.5);
         @include susy-breakpoint($desktop, $susy) {
            @include span(2);
         }
         .line {
            width: 10px;
            height: 100px;
            border: solid 1px $sky-blue;
            border-left: none;
            float: right;
         }
      }
      &:nth-child(10n+2){
         @include susy-breakpoint($mobile, $susy) {
            @include pad(0,0.25);
            @include span(5.75);
         }
         .block{
            .absolute-center{
               height: 75%;
               @include susy-breakpoint($mobile, $susy) {
                  height: 90%;
               }
               @include susy-breakpoint($desktop, $susy) {
                  height: 75%;
               }
            }
         }
      }
      &:nth-child(10n+3){
         @include susy-breakpoint($mobile, $susy) {
            @include pad(0.25,0);
            @include span(5.75);
         }
         .block{
            .absolute-center{
               height: 75%;
               @include susy-breakpoint($mobile, $susy) {
                  height: 90%;
               }
               @include susy-breakpoint($desktop, $susy) {
                  height: 75%;
               }
            }
         }
      }
      &:nth-child(10n+4){
         @include susy-breakpoint($mobile, $susy) {
            @include pad(0,0.25);
            @include span(5.75);
         }
         .block{
            .absolute-center{
               height: 90%;
               @include susy-breakpoint($mobile, $susy) {
                  height: 100%;
               }
               @include susy-breakpoint($desktop, $susy) {
                  height: 75%;
               }
            }
         }
      }
      &:nth-child(10n+5){
         @include susy-breakpoint($mobile, $susy) {
            @include pad(0.25,0);
            @include span(5.75);
         }
         .block{
            .absolute-center{
               height: 90%;
               @include susy-breakpoint($mobile, $susy) {
                  height: 100%;
               }
               @include susy-breakpoint($desktop, $susy) {
                  height: 75%;
               }
            }
         }
      }
   }
   .inner{
      @include pad(0.5,0.5);
      @include span(11);
      @include susy-breakpoint($tablet, $susy) {
         @include pad(1,1);
         @include span(10);
      }
   }
}