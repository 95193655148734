.form-registro{
	@include pad(0.5,0.5);
	@include span(11);
	margin-bottom: 2em;
	&__content{
		@include span(12);
		border-top: solid 1px $light-gray;
		padding: 1em 0;
		h2{
			@include fontSize(18px);
			text-transform: none;
			padding: 0.5em 0;
		}
		p{
			@include fontSize(12px);
		}
	}
	&__row{
		@include span(12);
		margin: 2em 0 0;
	}
	&__item{
		@include span(12);
		margin: 1em 0;
		&__content{
			@include span(12);
			.label{
				margin: 1em 0;
			}
			input{
				top: 15px;
			}
		}
	}
	&__buttons{
		@include span(12);
		margin-bottom: 2em;
		&__content{
			@include span(12);
			.btn{
	            margin: 1em auto;
	            width: 200px;
	            .blue-white-btn{
	               width: 100%;
	               @include fontSize(13px);
	            }
	        }
		}
	}
	#notificacion{
		text-align: center;
		padding: 0.5em 0;
	}
	@include susy-breakpoint($mobile, $susy){
		@include pad(1,1);
		@include span(10);
		&__item{
			@include gallery(6 of 12);
			&__content{
				@include pad(0,1);
				@include span(11);
			}
		}
		&__buttons{
			&__content{
				@include span(6 last);
			}
		}
	}
	@include susy-breakpoint($tablet, $susy){
		@include pad(0.5,0.5);
		@include span(11);
		&__item{
			@include gallery(4 of 12);
		}
		&__buttons{
			&__content{
				@include span(4 last);
				.btn{
					margin: 0;
					float: right;
				}
			}
		}
		#notificacion{
			text-align: right;
		}
	}
	@include susy-breakpoint($desktop, $susy){
		@include pad(1,1);
		@include span(10);
		&__item{
			@include gallery(3 of 12);
		}
		&__buttons{
			&__content{
				@include span(3 last);
			}
		}
	}
}