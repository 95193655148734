.aviso-privacidad {
	@include span(12);
	margin-top: 100px;
	margin-bottom: 80px;
	a {
		text-decoration: underline;
		color: $black;
	}
	h1 {
		text-align: left;
	}
	.principal {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h2 {
			padding: 1em 0;
			text-transform: none;
			font-size: 1.6rem;
		}
	}
	.identidad-domicilio {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h2 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		p {
			padding: 1.5em 0;
		}
	}
	.definiciones {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h2 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		p {
			padding: 1.5em 0;
		}
	}
	.datos-personal-recabados {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h2 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		ul {
   			@include pad(1,1);
			@include span(10);
   			list-style: initial;
   			li {
   				padding: 1em 0;
   			}
		}
	}
	.datos-personales-sensibles {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h2 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		p {
			padding: 1.5em 0;
		}
	}
	.finalidades {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h2 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		p {
			padding: 1.5em 0;
		}
		ol {
   			@include pad(1,1);
			@include span(10);
   			list-style: decimal;
   			li {
   				padding: 1em 0;
   			}
		}
	}
	.transferencia-datos {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h2 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		p {
			padding: 1.5em 0;
		}
		ol {
   			@include pad(1,1);
			@include span(10);
   			list-style: decimal;
   			li {
   				padding: 1em 0;
   			}
		}
	}
	.consentimiento-transferencia {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h2 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		p {
			padding: 1.5em 0;
		}
		ol {
   			@include pad(1,1);
			@include span(10);
   			list-style: decimal;
   			li {
   				padding: 1em 0;
   			}
		}
	}
	.derechos-arco {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h2 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		p {
			padding: 1.5em 0;
		}
		ul {
   			@include pad(1,1);
			@include span(10);
   			list-style: initial;
   			li {
   				padding: 1em 0;
   			}
		}
	}
	.revocacion-consentimiento {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h2 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		p {
			padding: 1.5em 0;
		}
	}
	.limitaciones {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h2 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		p {
			padding: 1.5em 0;
		}
	}
	.medios {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h2 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		p {
			padding: 1.5em 0;
		}
	}
	.modificaciones {
		@include pad(1,1);
		@include span(10);
		background-color: $white;
		text-align: left;
		h2 {
			padding: 1em 0;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.4rem;
		}
		p {
			padding: 1.5em 0;
		}
	}
}
