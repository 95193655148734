.simu-calcula-tu-credito{
	@include span(12);
	margin-bottom: 2em;
	&__content{
		@include span(12);
		h2{
			@include fontSize(15px);
			text-transform: none;
			padding: 0.5em 0;
		}
		p{
			@include fontSize(12px);
		}
	}
	&__row{
		@include span(12);
		margin: 2em 0 0;
	}
	&__item{
		@include span(12);
		margin: 1em 0;
		&__content{
			@include span(12);
			margin: 2em 0;
			&:nth-child(10n+1){
				margin: 0 0 2em;
			}
			.label{
				margin: 1em 0;
			}
			input{
				top: 15px;
			}
			.trigger-scroll{
				margin-top: 19px;
			}
		}
	}
	&__leyenda{
	   @include span(12);
	   color: $light-gray;
	   @include fontSize(11px);
	   margin: 0.5em 0;
	}
	&__buttons{
		@include span(12);
		margin: 2em 0;
		&__content{
			@include span(12);
			.btn{
	            margin: 1em auto;
	            width: 220px;
	            .orange-btn{
	               width: 100%;
	               @include fontSize(13px);
	               padding: 0.8em 2em;
	            }
	        }
		}
	}
	@include susy-breakpoint($mobile, $susy){
		&__item{
			@include span(12);
			&__content{
				@include span(5.5 of 12);
				&:nth-child(10n+1){
					@include pad(0,0.5);
				}
				&:nth-child(10n+2){
					@include pad(0.5,0);
					margin: 0 0 2em;
				}
				&:nth-child(10n+3){
					@include pad(3,3);
				}
			}
		}
	}
	@include susy-breakpoint($tablet, $susy){
	}
	@include susy-breakpoint($desktop, $susy){
		&__item{
			@include span(12);
			&__content{
				@include span(3.5 of 12);
				&:nth-child(10n+1){
					@include pad(0,0.5);
					margin: 0 0 2em;
				}
				&:nth-child(10n+2){
					@include pad(0,0.5);
					margin: 0 0 2em;
				}
				&:nth-child(10n+3){
					@include span(4 of 12);
					@include pad(0,0);
					margin: 0 0 2em;
				}
				.simu-tag{
					height: 16px;
				}
			}
		}
	}
}