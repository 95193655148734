.form-solicitud4{
	@include pad(0.5,0.5);
	@include span(11);
	margin-bottom: 2em;
	&__content{
		@include span(12);
		border-top: solid 1px $light-gray;
		padding: 1em 0;
		h2{
			@include fontSize(18px);
			text-transform: none;
			padding: 0.5em 0;
		}
		p{
			@include fontSize(12px);
		}
	}
	&__row{
		@include span(12);
		margin: 2em 0 0;
	}
	&__item{
		@include span(12);
		margin: 1em 0;
		&__content{
			@include span(12);
			margin: 2em 0;
			.label{
				margin: 1em 0;
			}
			input{
				top: 15px;
			}
			.trigger-scroll{
				margin-top: 16px;
			}
		}
	}
	&__buttons{
		@include span(12);
		margin-bottom: 2em;
		&__content{
			@include span(12);
			.btn{
	            margin: 1em auto;
	            width: 200px;
	            .blue-white-btn{
	               width: 100%;
	               @include fontSize(13px);
	            }
	        }
		}
	}
	#notificacion-solicitud-4{
		text-align: center;
	}
	@include susy-breakpoint($mobile, $susy){
		@include pad(1,1);
		@include span(10);
		&__item{
			@include span(12);
			margin: 1em 0;
			&__content{
				@include span(5.5 of 12);
				&:nth-child(10n+1),
				&:nth-child(10n+3),
				&:nth-child(10n+5),
				&:nth-child(10n+7),
				&:nth-child(10n+9){
					@include pad(0,0.5);
				}
				&:nth-child(10n+2),
				&:nth-child(10n+4),
				&:nth-child(10n+6),
				&:nth-child(10n+8){
					@include pad(0.5,0);
				}
				&:nth-child(10n+6){
					margin: 51px 0 4em;
				}
			}
		}
		&__buttons{
			@include pad(2,0)
			@include span(10);
			&__content{
				@include span(6 last of 12);
				.btn{
		            margin: 0;
		            float: right;
		        }
			}
		}
		#notificacion-solicitud-4{
			text-align: right;
		}
	}
	@include susy-breakpoint($tablet, $susy){
		@include pad(0.5,0.5);
		@include span(11);
		&__item{
			&__content{
				@include span(3.5 of 12);
				&:nth-child(10n+1),
				&:nth-child(10n+2){
					margin: 19px 0 2em;
				}
				&:nth-child(10n+3){
					margin: 0 0 2em;
				}
				&:nth-child(10n+1),
				&:nth-child(10n+4),
				&:nth-child(10n+7){
					@include pad(0,0.5);
				}
				&:nth-child(10n+2),
				&:nth-child(10n+5),
				&:nth-child(10n+8){
					@include pad(0.25,0.25);
				}
				&:nth-child(10n+3),
				&:nth-child(10n+6),
				&:nth-child(10n+9){
					@include pad(0.5,0);
				}
			}
		}
		&__buttons{
			@include pad(3.5,0)
			@include span(8.5);
		}
	}
	@include susy-breakpoint($desktop, $susy){
		@include pad(1,1);
		@include span(10);
		&__item{
			&__content{
				@include span(2.75 of 12);
				&:nth-child(10n+1),
				&:nth-child(10n+2){
					margin: 19px 0 2em;
				}
				&:nth-child(10n+3),
				&:nth-child(10n+4),
				&:nth-child(10n+9){
					margin: 0 0 2em;
				}
				&:nth-child(10n+1),
				&:nth-child(10n+2),
				&:nth-child(10n+3),
				&:nth-child(10n+4),
				&:nth-child(10n+5),
				&:nth-child(10n+6),
				&:nth-child(10n+7),
				&:nth-child(10n+8),
				&:nth-child(10n+9){
					@include pad(0,0.25);
				}
				&:nth-child(10n+7),
				&:nth-child(10n+8){
					margin: 0 0 4em;
				}
				&:nth-child(10n+5){
					margin: 0 0 2em;
				}
				&:nth-child(10n+6){
					margin: 19px 0 4em;
				}
			}
		}
		&__buttons{
			@include pad(4.5,0)
			@include span(7.5);
		}
	}
}
