/*---- mixins.scss ----*/

@mixin box-shadow($arguments) {
   -webkit-box-shadow: $arguments;
	-moz-box-shadow: $arguments;
	-o-box-shadow: $arguments;
	box-shadow: $arguments;
}

@mixin border-box {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

@mixin transition($arguments) {
	transition: $arguments;
	-moz-transition: $arguments;
	-ms-transition: $arguments;
	-o-transition: $arguments;
	-webkit-transition: $arguments;
}

@mixin rotate($degrees) {
	-webkit-transform: rotate($degrees);
	-moz-transform: rotate($degrees);
	-ms-transform: rotate($degrees);
	-o-transform: rotate($degrees);
	transform: rotate($degrees);
}

@mixin transform($arguments) {
   -moz-transform: $arguments;
   -o-transform: $arguments;
   -ms-transform: $arguments;
	-webkit-transform: $arguments;
   transform: $arguments;
}

@mixin transform-origin ($origin) {
   -moz-transform-origin: $origin;
   -o-transform-origin: $origin;
   -ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
   transform-origin: $origin;
}

@mixin opacity($arguments) {
	-webkit-filter: opacity($arguments);
	-moz-filter: opacity($arguments);
	-ms-filter: opacity($arguments);
	-o-filter: opacity($arguments);
	filter: opacity($arguments);
	opacity: $arguments;
}

@mixin border-radius($arguments) {
	-webkit-border-radius: $arguments;
	-moz-border-radius: $arguments;
	-o-border-radius: $arguments;
	border-radius: $arguments;
}

@mixin appearance {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
   appearance: none;
}

@mixin absolute {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

$font-size-base: 14px;

@mixin fontSize($font){
  font-size: ($font / $font-size-base) * 1rem;
}

@mixin fullLine($height, $color){
  position: absolute;
  display: block;
  width: 100%;
  height: $height;
  background-color: $color;
}
