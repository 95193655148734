.quienes-somos {
   @include span(12);
   background-color: $white;
   .how-header-desktop {
      @include span(12);
      position: relative;
      .how-bg {
         @include span(12);
         position: relative;
         background-repeat: no-repeat;
         background-position: center;
         background-size: cover;
         background-image: url('/images/foto_mobile_quienesomos.png');
      }
      .how-inner {
         @include span(12);
         margin: auto;
         @include absolute;
         h1 {
            text-align: center;
            color: $white;
            font-size: 2rem;
         }
      }
   }
   .how-content-desktop {
      margin: 1em 0 0;
      h2 {
         text-transform: none;
         margin: 0 0 1em;
      }
      p {
         text-align: left;;
         color: $black;
      }
   }
}

.quienes-somos {
   @include susy-breakpoint($phone, $susy) {
      .how-header-desktop {
         display: block;
         @include span(12);
         height: 80vh;
         .how-bg {
            height: 100%;
            background-image: url('/images/foto_tablet_quienesomos.png');
         }
         .how-inner {
            height: 20%;
            width: 250px;
            h1 {
               font-size: 2rem;
            }
            .cursive {
               font-size: 1.6em;
            }
         }
      }
      .how-content-desktop {
         @include span(12);
         .how-content-inner {
            h2{
               font-size: 1.25rem;
            }
            @include pad(1,1);
            @include span(10);
            .ver-mas-btn {
               display: block;
               float: right;
            }
            p {
               margin-bottom: 5%;
            }
            .blue-line-down {
               @include span(12);
               margin: 3% 0;
               .line-down-img {
                  @include span(12);
               }
            }
            .nuestros-valores {
               @include span(12);
               .heading {
                  @include span(12);
                  text-align: left;
               }
               .valores {
                  @include span(12);
                  .valor {
                     @include span(12);
                     margin-bottom: 10%;
                     .icon {
                        @include span(3);
                        .imag {
                           width: 50px;
                           height: 50px;
                           margin-left: 15%;
                        }
                     }
                     .vertical-line {
                        @include span(1);
                        .imag-arrow {
                           width: 15px;
                           height: 220px;
                        }
                     }
                     .text {
                        @include span(8);
                        p {
                           font-size: 0.9rem;
                           text-align: left;
                        }
                        h5 {
                           color: $black;
                           text-transform: none;
                           font-size: 1rem;
                        }
                     }
                  }
               }
            }
         }
      }
   }
   @include susy-breakpoint($mobile, $susy) {
      .how-header-desktop {
         display: block;
         @include span(12);
         height: 80vh;
         .how-bg {
            background-image: url('/images/foto_desk_quienesomos.png');
         }
         .how-inner {
            height: 20%;
            width: 100%;
            h1 {
               font-size: 2rem;
            }
            .cursive {
               font-size: 1.6em;
            }
         }
      }
      .how-content-desktop {
         @include span(12);
         .how-content-inner {
            @include pad(1,1);
            @include span(10);
            h2{
               font-size: 1.25rem;
            }
            p {
               margin-bottom: 5%;
            }
            .blue-line-down {
               @include span(12);
               margin: 3% 0;
               .line-down-img {
                  @include span(12);
               }
            }
            .nuestros-valores {
               @include span(12);
               .heading {
                  @include span(12);
                  text-align: left;
               }
               .valores {
                  @include span(12);
                  .valor {
                     @include span(12);
                     margin-bottom: 10%;
                     .icon {
                        @include span(3);
                        .imag {
                           width: 50px;
                           height: 50px;
                           margin-left: 30%;
                        }
                     }
                     .vertical-line {
                        @include span(1);
                        .imag-arrow {
                           width: 15px;
                           height: 150px;
                        }
                     }
                     .text {
                        @include span(8);
                        p {
                           font-size: 0.95rem;
                           text-align: left;
                        }
                        h5 {
                           color: $black;
                           text-transform: none;
                           font-size: 1rem;
                        }
                     }
                  }
               }
            }
         }
      }
   }
   @include susy-breakpoint($tablet, $susy) {
      .how-header-desktop {
         display: block;
         @include span(12);
         height: 70vh;
         .how-bg {
            background-image: url('/images/foto_desk_quienesomos.png');
         }
         .how-inner {
            height: 20%;
            width: 250px;
            h1 {
               font-size: 2.2rem;
            }
            .cursive {
               font-size: 1.6em;
            }
         }
      }
      .how-content-desktop {
         @include span(12);
         .how-content-inner {
            @include pad(2,2);
            @include span(8);
            h2{
               font-size: 1.35rem;
            }
            p {
               margin-bottom: 5%;
            }
            .blue-line-down {
               @include span(12);
               margin: 3% 0;
               .line-down-img {
                  @include span(12);
               }
            }
            .nuestros-valores {
               @include span(12);
               .heading {
                  @include span(12);
                  text-align: left;
               }
               .valores {
                  @include span(12);
                  .valor {
                     @include span(12);
                     margin-bottom: 10%;
                     .icon {
                        @include span(3);
                        .imag {
                           width: 50px;
                           height: 50px;
                           margin-left: 30%;
                        }
                     }
                     .vertical-line {
                        @include span(1);
                        .imag-arrow {
                           width: 15px;
                           height: 150px;
                        }
                     }
                     .text {
                        @include span(8);
                        p {
                           font-size: 0.95rem;
                           text-align: left;
                        }
                        h5 {
                           color: $black;
                           text-transform: none;
                           font-size: 1rem;
                        }
                     }
                  }
               }
            }
         }
      }
   }
   @include susy-breakpoint($desktop, $susy) {
      .how-header-desktop {
         display: block;
         @include span(12);
         height: 70vh;
         .how-bg {
            background-image: url('/images/foto_desk_quienesomos.png');
         }
         .how-inner {
            height: 20%;
            width: 250px;
            h1 {
               font-size: 2.5rem;
            }
            .cursive {
               font-size: 1.6em;
            }
         }
      }
      .how-content-desktop {
         @include span(12);
         .how-content-inner {
            @include pad(2,2);
            @include span(8);
            h2{
               font-size: 1.45rem;
            }
            p {
               margin-bottom: 5%;
            }
            .blue-line-down {
               @include span(12);
               margin: 3% 0;
               .line-down-img {
                  @include span(12);
               }
            }
            .nuestros-valores {
               @include span(12);
               .heading {
                  @include span(12);
                  text-align: left;
               }
               .valores {
                  @include span(12);
                  .valor {
                     @include gallery(6 of 12);
                     margin-bottom: 10%;
                     .icon {
                        @include span(3);
                        .imag {
                           width: 50px;
                           height: 50px;
                           margin-left: 30%;
                        }
                     }
                     .vertical-line {
                        @include span(1);
                        .imag-arrow {
                           width: 15px;
                           height: 150px;
                        }
                     }
                     .text {
                        @include span(8);
                        p {
                           font-size: 0.95rem;
                           text-align: left;
                        }
                        h5 {
                           color: $black;
                           text-transform: none;
                           font-size: 1rem;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
