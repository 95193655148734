.form-solicitud3{
	@include pad(0.5,0.5);
	@include span(11);
	margin-bottom: 2em;
	&__content{
		@include span(12);
		border-top: solid 1px $light-gray;
		padding: 1em 0;
		h2{
			@include fontSize(18px);
			text-transform: none;
			padding: 0.5em 0;
		}
		p{
			@include fontSize(12px);
		}
		.label{
			@include fontSize(12px);
			label{
				color: $black;
				@include fontSize(12px);
			}
		}
	}
	&__row{
		@include span(12);
		margin: 2em 0 0;
	}
	&__item{
		@include span(12);
		margin: 1em 0;
		&__content{
			@include span(12);
			margin: 2em 0;
			&:nth-child(10n+1){
				margin: 0 0 2em;
			}
			.label{
				margin: 1em 0 2em;
			}
			input{
				top: 0;
			}
			.input-tdc{
				@include span(6 of 12);
				height: 30px;
			}
		}
	}
	&__buttons{
		@include span(12);
		margin-bottom: 2em;
		&__content{
			@include span(12);
			.btn{
	            margin: 1em auto;
	            width: 200px;
	            .blue-white-btn{
	               width: 100%;
	               @include fontSize(13px);
	            }
	        }
		}
	}
	#notificacion-solicitud-3{
		text-align: center;
	}
	@include susy-breakpoint($mobile, $susy){
		@include pad(1,1);
		@include span(10);
		&__item{
			@include span(12);
			margin: 1em 0;
			&__content{
				&:nth-child(10n+1){
					@include pad(0,0.25);
					@include span(5.75 of 12);
					margin: 0;
				}
				&:nth-child(10n+2){
					@include pad(0.25,0);
					@include span(5.75 of 12);
					margin: 0;
				}
				&:nth-child(10n+3){
					@include pad(0,0.25);
					@include span(5.75 of 12);
				}
				&:nth-child(10n+4){
					@include pad(0.25,0);
					@include span(5.75 of 12);
				}
				@include span(12 of 12);
				.input-tdc{
					@include span(4 of 12);
				}
			}
		}
		&__buttons{
			@include pad(2,0)
			@include span(10);
			&__content{
				@include span(6 last of 12);
				.btn{
		            margin: 0;
		            float: right;
		        }
			}
		}
		#notificacion-solicitud-3{
			text-align: right;
			margin: 1em 0;
		}
	}
	@include susy-breakpoint($tablet, $susy){
		@include pad(0.5,0.5);
		@include span(11);
		&__buttons{
			@include pad(3.5,0)
			@include span(8.5);
		}
	}
	@include susy-breakpoint($desktop, $susy){
		@include pad(1,1);
		@include span(10);
		&__item{
			@include span(12);
			margin: 1em 0;
			&__content{
				&:nth-child(10n+1){
					@include pad(0,0.25);
					@include span(2.75 of 12);
					margin: 0;
				}
				&:nth-child(10n+2){
					@include pad(0.25,0);
					@include span(2.75 of 12);
					margin: 0;
				}
				&:nth-child(10n+3){
					@include pad(0,0.25);
					@include span(2.75 of 12);
					margin: 0;
				}
				&:nth-child(10n+4){
					@include pad(0.25,0);
					@include span(2.75 of 12);
					margin: 0;
				}
				@include span(12 of 12);
				.input-tdc{
					@include span(4 of 12);
				}
			}
		}
		&__buttons{
			@include pad(4.5,0)
			@include span(7.5);
		}
	}
}
