.custom-select{
  @include span(12);
  height: 45px;
  text-align: center;
  color: $black;
  position: relative;
  &__content{
    @include span(12);
    height: 100%;
    background: transparent url(/images/icons/down_arrow.png) 98% center no-repeat;
    height: 35px;
    overflow: hidden;
    .trigger-scroll{
      @include pad(1,1);
      @include span(10);
      @include transition(all 0.25s ease);
      @include fontSize(14px);
      text-transform: uppercase;
      label {
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
  &__line{
    width: 100%;
    height: 10px;
    position: absolute;
    bottom: 5px;
    left: 0;
    border: solid 1px $sky-blue;
    border-top: none;
  }
  a{
    color: $black;
  }
  .dropdownvisible-scroll{
    width: 100%;
    //height: 95px;
    border: solid 1px $light-gray;
    //overflow-y: scroll;
    color: $black;
    background-color: $white;
    position: relative;
    top: 10px;
    z-index: 2;
    @include transition(all 0.5s ease);
    li{
      @include fontSize(13px);
      color: $black;
      border-radius: 5px;
      padding: 8px;
      @include transition(all 0.5s ease);
      text-transform: uppercase;
      &:hover{
        background-color: $sky-blue;
        color: $white;
        cursor: pointer;
      }
    }
  }
  .dropdownhidden-scroll{
    display: none;
  }
}

/*conoce-y-ahorra*/
.simu-custom-select{
  @include span(12);
  height: 60px;
  text-align: center;
  color: $black;
  position: relative;
  &__content{
    @include span(12);
    height: 100%;
    background: transparent url(/images/icons/down_arrow.png) 98% center no-repeat;
    height: 50px;
    overflow: hidden;
    .trigger-scroll{
      @include pad(1,1);
      @include span(10);
      @include transition(all 0.25s ease);
      @include fontSize(24px);
      text-transform: uppercase;
    }
  }
  &__line{
    width: 100%;
    height: 10px;
    position: absolute;
    bottom: 5px;
    left: 0;
    border: solid 1px $sky-blue;
    border-top: none;
  }
  a{
    color: $black;
  }
  .dropdownvisible-scroll{
    width: 100%;
    //height: 95px;
    border: solid 1px $light-gray;
    //overflow-y: scroll;
    color: $black;
    background-color: $white;
    position: relative;
    top: 10px;
    z-index: 2;
    @include transition(all 0.5s ease);
    li{
      @include fontSize(13px);
      color: $black;
      border-radius: 5px;
      padding: 8px;
      @include transition(all 0.5s ease);
      text-transform: uppercase;
      &:hover{
        background-color: $sky-blue;
        color: $white;
        cursor: pointer;
      }
    }
  }
  .dropdownhidden-scroll{
    display: none;
  }
}
/*End conoce-y-ahorra*/
/*tamaño de dropdowns*/
.plazo-drop-size{
   height: 210px;
   overflow-y: auto;
}
.finalidad-drop-size{
   height: 225px;
   overflow-y: scroll;
}
.buro-drop-size,
.residencia-drop-size{
   height: 105px;
   overflow-y: auto;
}
.colonia-drop-size{
   height: 140px;
   overflow-y: scroll;
}
.colonia-1-drop-size{
   height: 40px;
   overflow-y: auto;
}
.estado-drop-size{
   height: 140px;
   overflow-y: scroll;
}
.drop-size{
   height: 165px;
   overflow-y: scroll;
}
.sexo-drop-size{
   height: 70px;
   overflow-y: auto;
}
.estadoCivil-drop-size{
   height: 175px;
   overflow-y: auto;
}
/*end tamaño de dropdowns*/
